import { ReactNode } from 'react';
import { FaCirclePlus } from 'react-icons/fa6';

type AddButtonProps = {
  children : ReactNode
  clickEvent : React.MouseEventHandler<HTMLButtonElement>
}
const AddButton = ({children, clickEvent} : AddButtonProps) => {
  return (
    <>
        <button onClick={clickEvent} 
        className="flex-initial h-[50px] p-5 pr-10 bg-red-700 rounded-[10px] justify-center items-center gap-2.5 inline-flex">
            <FaCirclePlus className="w-6 h-6 p-0.5 justify-center items-center flex text-red-700 rounded-full bg-white" />
            <div className="text-white text-[20px] font-light font-kantipur leading-normal">{children}</div>
        </button>
    </>
  )
}

export default AddButton

// usage
// const clickEvent= () => {
//     console.log('button clicked')
// }