import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'
import search from '../assets/search.svg'
import { bsToAd } from '@sbmdkl/nepali-date-converter';

export interface FilterParams {
    searchInput: string;
    type: string;
    dateFrom: string;
    dateTo: string;
}

interface FilterModalProps {}

export interface FilterModalHandle {
    show: () => void;
    hide: () => void;
}

const FilterModal = forwardRef<FilterModalHandle, FilterModalProps>((props,ref) => {
    const [filterParams,setFilterParams] = useState<FilterParams>({
        searchInput: "",
        type:"",
        dateFrom: '',
        dateTo:'',
    })
    const [bsYear,setBsYear] = useState<string>('2081')
    const handleInputChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        setFilterParams({...filterParams,searchInput:e.target.value})        
    }
    const formatDate = (dateString:string) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const handleDateFromChange = (date: Date | null) => {
        if (date) {
        setFilterParams({
            ...filterParams,
            dateFrom: formatDate(date.toISOString()), // Updating date in filterParams
        });}
    }
    const handleDateToChange = (date: Date | null) => {
        if (date) {
        setFilterParams({
            ...filterParams,
            dateTo: formatDate(date.toISOString()), // Updating date in filterParams
        });
        }
    }

    const navigate = useNavigate()
    const handleApplyFilter = () => {
        setIsVisible(false);
        console.log(filterParams)
        navigate('/attendance', { state: { filterParams: filterParams } });
    }
    const [isVisible, setIsVisible] = useState(false);

    useImperativeHandle(ref, () => ({
      show: () => setIsVisible(true),
      hide: () => setIsVisible(false),
    }));
  
    const hide = () => {
        setIsVisible(false)
        setFilterParams({
            searchInput: "",
            type:"",
            dateFrom: '',
            dateTo:'',
        })
        navigate('/attendance', { state: { filterParams: {
            searchInput: "",
            type:"",
            dateFrom: '',
            dateTo:'',
        } } })
    };
    const nepaliMonths = [
        'Baisakh', 'Jestha', 'Asar', 'Shrawan',
        'Bhadra', 'Ashwin', 'Kartik', 'Mangsir',
        'Poush', 'Magh', 'Falgun', 'Chaitra'
    ];
    const nepaliYears = ['2079','2080','2081','2082','2083','2084','2085','2086','2087','2088']
    const handleNepaliDate = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        const monthIndex = parseInt(event.target.value, 10)+1
        // const monthIndex = parseInt(event.target.value, 10);
        
        let monthEnd = 32
        while (true) {
            try {
              let startDate = bsToAd(`${bsYear}-${monthIndex}-01`)
              let endDate = bsToAd(`${bsYear}-${monthIndex}-${monthEnd}`);
              setFilterParams({
                    ...filterParams,
                    dateFrom: startDate,
                    dateTo: endDate,
                })
              break; // If no error, break out of the loop
            } catch (error) {
              // If error occurs (e.g., invalid date), decrease monthEnd by 1 and try again
              monthEnd--;
            }
        }
       
    };

    return (
        <>
        {isVisible &&
        <div className="fixed flex inset-0 bg-opacity-30 backdrop-blur-sm justify-center max-sm:items-start items-center">
            <div className="bg-white rounded-[10px] shadow p-5 w-[22rem]">
                <div className="text-zinc-900 text-[24px] font-semibold font-kantipur leading-[30px] mb-4">{'lkmn\\6/'}</div>
                <div className="border border-zinc-400/10"/>


                <div className="inline-flex gap-2.5 px-4 py-3 mt-5">
                    <img src={search} alt='searchIcon' className="h-6 w-6"></img>
                    <input id='searchInputControl' 
                    name="searchInput"
                    value={filterParams.searchInput}
                    onChange={handleInputChange}
                    className="grow shrink basis-0 text-sm outline-none font-light font-lexend leading-normal placeholder:font-kantipur placeholder:text-[20px]"
                    placeholder="sd{rf/L vf]hL ug{'xf];"/>
                </div>

                <div className="mt-5 space-y-4">
                    <div className="text-zinc-900 text-[20px] font-semibold font-kantipur leading-normal">{'ldltsf] cGt/fn'}</div>
                    {/* -----------------options--------------------- */}

                    <div className="flex flex-wrap gap-4">
                    <ReactDatePicker
                            id="dateFrom"
                            selected={filterParams.dateFrom ? new Date(filterParams.dateFrom) : null}
                            onChange={handleDateFromChange}
                            placeholderText="Date From" // Placeholder text for the date picker
                            className="w-[100px] border p-2 rounded"
                        />
                    <ReactDatePicker
                            id="dateTo"
                            selected={filterParams.dateTo ? new Date(filterParams.dateTo) : null}
                            onChange={handleDateToChange}
                            placeholderText="Date To" // Placeholder text for the date picker
                            className="w-[100px] border p-2 rounded"
                        />
                    </div>
                </div>

                <div className="mt-5 space-y-3">
                    <div className="text-zinc-900 text-[20px] font-semibold font-kantipur leading-normal">{'k|sf/ rog ug{\'xf]:'}</div>
                    <div  className="inline-flex gap-4">
                        <div className="inline-flex gap-2.5">
                            <input type="radio"
                                id="type1"
                                name="type"
                                value="admin"
                                onChange={(e)=>setFilterParams({...filterParams,type:e.target.value})}
                                />
                            <p>admin</p>
                        </div>
                        <div className="inline-flex gap-2.5">
                            <input type="radio"
                                id="type2"
                                name="type"
                                value="user"
                                onChange={(e)=>setFilterParams({...filterParams,type:e.target.value})}
                                />
                            <p>user</p>
                        </div>
                    </div>
                </div>

                <div className="flex gap-2.5 pt-3 justify-center pr-10">
        
                    <button className="px-8 py-3 rounded-[10px] border border-zinc-400/20 justify-center items-center inline-flex text-zinc-900 text-sm font-normal font-['Poppins']" 
                    onClick={hide}>Cancel</button>
                    <button className="px-10 py-3 bg-red-700 rounded-[10px] justify-center items-center inline-flex text-white text-sm font-normal font-['Poppins']"
                    onClick={handleApplyFilter}>Apply</button>

                </div>


            </div>
        </div>
        }
        </>
    )
})

export default FilterModal
