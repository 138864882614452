import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { UserService } from '../../../Services/User.service'

interface ForgotPageProps {
    userService : UserService
}

export const Forgotpage = ({userService}:ForgotPageProps) => {
    const [email,setEmail] = useState<string>('')
    const navigate = useNavigate()
    const handleResetPasswordLink = async (e: React.MouseEvent<HTMLButtonElement>)=>{
        e.preventDefault()
        try{            
            userService.setToken(localStorage.getItem('token'))
            const resetLink = await userService.resetPasswordLink({
                email: email,
            })
            if (resetLink.msg) {
                navigate('/login', { state: { noti: resetLink.msg } })
                setEmail('')
            } 
            else {
                const noti=resetLink.data.errors[0].description
                navigate('', { state: { noti: noti, status: 'error' } })
            }
        } catch (exception) {
            throw (exception)
        }
        }

  return (
        <>            
            <div className="flex h-[100vh] justify-center items-center">
                
                <div className="flex-col justify-start items-start gap-[30px] flex">
                    <div className="flex-col justify-start items-start gap-[5px] flex">
                        <div className="w-[445px] text-zinc-900 text-3xl font-semibold font-['Lexend'] leading-10">Forgot Password</div>
                        <div className="w-[445px] text-zinc-400 text-base font-light font-['Lexend'] leading-normal">Enter your registered email address. we’ll send you a code to reset your password.</div>
                    </div>
                    <div className="w-[445px] flex-col justify-start items-start gap-8 flex">
                        <div className="bg-white rounded-lg flex-col justify-start items-end gap-4 flex w-full">
                            <div className="rounded-[10px] flex-col justify-start items-start gap-5 flex w-full">
                                <div className="h-[55px] px-4 py-[7px] rounded-[10px] border border-red-700 flex-col justify-start items-start gap-2.5 flex w-full">
                                    <div className="self-stretch justify-start items-center gap-2.5 inline-flex w-full">
                                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-px inline-flex">
                                        <div className="self-stretch text-red-700 text-[11px] font-light font-['Lexend'] leading-none">Email Address</div>
                                        <input type="email" id="email" className="self-stretch text-zinc-900 text-base font-light font-lexend leading-normal outline-none" placeholder="Enter your Email"
                                        value={email || ''}
                                        onChange={(event)=>setEmail(event.target.value)}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-[445px] h-14 p-5 bg-red-700 rounded-[10px] justify-center items-center gap-2.5 inline-flex">
                            <Link to='/login/otppage'>
                                <button type="submit" className="text-white text-base font-medium font-['Lexend'] leading-normal"
                                onClick={handleResetPasswordLink}>Send Reset Link</button>
                            </Link>
                        </div>
                        <div className="self-stretch grow shrink basis-0 px-[194px] justify-start items-center gap-[5px] inline-flex">
                            <Link to="/">
                                <div className="text-zinc-900 text-base font-light font-['Lexend'] leading-normal">Cancel</div>
                            </Link>
                        </div>
                    </div>
                </div>

            </div>
        </>
  )
}