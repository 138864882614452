import React,{ReactNode} from 'react'
import { FaAngleDown } from 'react-icons/fa'

type DropdownProps = {
    children: ReactNode
}
const Dropdown = ({children}:DropdownProps) => {
  return (
    <>
        <div className="inline-flex items-center h-10 p-3 rounded-[10px] border border-zinc-400/20 justify-end gap-2.5">
            <p className="text-right text-zinc-900 text-lg font-light font-kantipur leading-snug">{children}</p>
            <FaAngleDown/>
        </div>
    </>
  )
}

export default Dropdown
