import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';

export interface PageData {
    page_number: number;
    page_size: number;
    page_count: number;
    total_items: number;
}

type PaginationSectionProps = {
    page : number
    setPage : React.Dispatch<React.SetStateAction<number>>
    pageSize : number
    setPageSize : React.Dispatch<React.SetStateAction<number>>
    pageData : PageData
}

const PaginationSection = ({ page, setPage,pageSize, setPageSize, pageData} : PaginationSectionProps) => {
    const visibleNumbers = []
    for (let i = Math.max(1, Math.min(page, pageData.page_count - 3)); i <= Math.min(page + 3, pageData.page_count); i++) {
        visibleNumbers.push(i);
    }

    const handlePageSize = async (event:React.ChangeEvent<HTMLSelectElement>) => {
        setPageSize(parseInt(event.target.value,10))
        setPage(1)
    }

    const handleIncrement = () => {
        const nextPage = page + 1 <= pageData.page_count ? page + 1 : page
        setPage(nextPage);
    }

    const handleDecrement = () => {
        const prevPage = page - 1 >= 1 ? page - 1 : 1
        setPage(prevPage);
    }

  return (
    <>
       <div className='flex justify-between w-full px-4 pr-8 py-4 flex-wrap items-center'>
                  <div className='inline-flex items-center gap-5'>
                      <div className="text-zinc-400 text-sm font-light font-['Lexend'] leading-snug">Showing</div>
                      <div className='inline-flex items-center px-3 py-3 gap-4 rounded-[10px] border border-zinc-400/20'>
                          <select id="pageSize" onChange={handlePageSize} className='outline-none' value={pageSize}>
                              <option value='10'>10</option>
                              <option value='8'>8</option>
                              <option value='6'>6</option>
                              <option value='3'>3</option>
                          </select>
                      </div>
                  </div>
                  <div className="text-zinc-400 text-sm font-light font-['Lexend'] leading-snug">Showing {(page-1)*(pageSize)+1} to {pageSize<pageData.total_items?(page)*(pageSize):pageData.total_items} out of {pageData.total_items} records</div>
                  <div className='inline-flex items-center'>
                      <FaAngleLeft onClick={handleDecrement} className='hover:cursor-pointer'/>
                      <div className='inline-flex px-2 gap-2'>
                          {visibleNumbers.map((number) => (
                              <div key={number} 
                                  className={`text-center hover:cursor-pointer px-1.5 py-1 gap-2 font-lexend text-base font-light leading-normal ${page === number ? 'border border-red-700 rounded-lg' : 'text-zinc-900'}`}
                                  onClick={() => setPage(number)}
                                  >
                                  {number}
                              </div>
                          ))}
                      </div>
                      <FaAngleRight onClick={handleIncrement} className='hover:cursor-pointer'/>
                  </div>
              </div>
    </>
  )
}

export default PaginationSection