import React, { useEffect, useState } from 'react'
import Calendar from '../../Partials/Calendar/Calendar'
import ToDoList from '../../Partials/ToDoList/ToDoList';
import TaskOverview from '../../Partials/TaskOverview/TaskOverview';
import YellowCard from '../../Components/Ui/YellowCard';
import GreenCard from '../../Components/Ui/GreenCard';
import RedCard from '../../Components/Ui/RedCard';
import { IoPersonOutline } from 'react-icons/io5';
import { PiBriefcase } from "react-icons/pi"
import { BsPersonXFill } from "react-icons/bs"
import Chart from '../../Partials/Chart';
import EmployeeStatus from '../../Partials/EmployeeStatus';
import { EmployeeService } from '../../Services/Employee.service';
import { PageData } from '../../Components/PaginationSection';
import { EmployeeData } from '../../models/Employee';

type DashboardSectionProps = {
  employeeService : EmployeeService
}
export type recent_logsType = {
  employee_id: number
  status: string
  employee: EmployeeData
}

export const DashboardSection = ({employeeService}:DashboardSectionProps) => {
  const [presentAbsent,setPresentAbsent] = useState<{
        total_employee: number,
        total_present: number,
        total_absent: number}>({
          total_employee: 0,
        total_present: 0,
        total_absent: 0
        })
  const [employeeStatus,setEmployeeStatus] = useState<{recent_logs:recent_logsType[]}>({recent_logs:[]})

  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [pageData, setPageData] = useState<PageData>({ 
  page_size: 10,
  page_count: 0,
  total_items: 0,
  page_number: 1})


  useEffect(() => {
    const fetchData = async () => {

        try{
            employeeService.setToken(localStorage.getItem('token'))
            const res = await employeeService.getEmployeePresentAbsentView()
             // Check the structure of the response
            if (res.data && res.data.total_employee !== undefined) {
              setPresentAbsent({
                total_employee: res.data.total_employee,
                total_present: res.data.total_present,
                total_absent: res.data.total_absent,
              });
              setPageData(res.data.meta)
              setEmployeeStatus(res.data)
            } else {
              console.error('Unexpected response structure:', res);
            }
        } catch (exception) {
          console.error('Error fetching data:', exception);
        }
            
    }
    fetchData()
  },[employeeService])

  return (
  <div className='flex flex-row w-full flex-wrap justify-evenly lg:pr-4'>
                <div className='flex flex-col space-y-8 mx-3'>
                    
                    {/* ---------------------------- Card Section----------------------------------------- */}
                    <div className='flex gap-8 flex-wrap  max-sm:flex-col max-sm:items-center flex-row'>
                        <YellowCard component={IoPersonOutline} text={"s'n sd{rf/L "} num={presentAbsent.total_employee} />
                        <GreenCard component={PiBriefcase} text={"pkl:yt sd{rf/Lx¿"} num={presentAbsent.total_present} />
                        <RedCard component={BsPersonXFill} text={"cg'kl:yt sd{rf/Lx¿"} num={presentAbsent.total_absent}/>
                    </div>
                    
                    {/* ------------------------------------Chart Section --------------------------------------------------- */}
                    <Chart/>
                    {/* --------------------------------------------------Employee Status Section-------------------------------------------- */}
                    <EmployeeStatus
                    page = {page}
                    setPage = {setPage}
                    pageSize={pageSize}
                    setPageSize = {setPageSize}
                    pageData = {pageData}
                    recent_logs={employeeStatus.recent_logs}/>
                    

                </div>

    <div className='flex flex-wrap xl:w-[330px] max-lg:w-full   space-y-3 mobile:w-full mobile:justify-center'>
      <div className='ml-3 max-xl:mt-4 mt-4'>
        <Calendar/>
      </div>
      <div className='ml-3'>
        <ToDoList/>
        <TaskOverview/>
      </div>
        
    </div>
  </div>

  )
}
