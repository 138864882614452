import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { bsToAd,adToBs } from '@sbmdkl/nepali-date-converter';
import { useNavigate } from 'react-router-dom';

interface FilterParams {
    dateFrom: string;
    dateTo: string;
    month:string;
}

interface FilterModalProps {
    filterParams: FilterParams;
    setSelectedOption:React.Dispatch<React.SetStateAction<string>>
    setFilterParams: React.Dispatch<React.SetStateAction<FilterParams>>;
}

export interface FilterModalHandle {
    show: () => void;
    hide: () => void;
}

const FilterModal2 = forwardRef<FilterModalHandle, FilterModalProps>(({ filterParams, setFilterParams,setSelectedOption },ref) => {

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState<number>(0);
    const getFiscalYears = (): string[] => {
        const currentDate = new Date();
        const currentNepaliYear = adToBs(formatDate(currentDate.toISOString()));
        const year = parseInt((currentNepaliYear as string).substring(0, 4), 10);
        const prevYear = year - 1;
        return [year.toString(), prevYear.toString()];
    };

    const [bsYear, setBsYear] = useState<string>(getFiscalYears()[0]);

    useImperativeHandle(ref, () => ({
        show: () => setIsVisible(true),
        hide: () => setIsVisible(false),
    }));

    const hide = () => {
        setIsVisible(false);
        setFilterParams({
            dateFrom: '',
            dateTo: '',
            month: ''
        });
    };

    const handleApplyFilter = () => {
        setIsVisible(false);
        setSelectedOption('attendance');
        navigate('attendance-detail');
    };

    useEffect(() => {
        if (selectedMonth !== 0) {
            handleNepaliDate(selectedMonth);
        }
        // eslint-disable-next-line
    }, [bsYear]);


    const nepaliMonths = [
        'Baisakh', 'Jestha', 'Asar', 'Shrawan',
        'Bhadra', 'Ashwin', 'Kartik', 'Mangsir',
        'Poush', 'Magh', 'Falgun', 'Chaitra'
    ];

    const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setBsYear(event.target.value);
    };

    const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const monthIndex = parseInt(event.target.value, 10) + 1;
        setSelectedMonth(monthIndex);
        handleNepaliDate(monthIndex);
    };

    const handleNepaliDate = async (monthIndex: number) => {
        let monthEnd = 32;
        while (true) {
            try {
                let startDate = bsToAd(`${bsYear}-${monthIndex}-01`);
                let endDate = bsToAd(`${bsYear}-${monthIndex}-${monthEnd}`);
                setFilterParams({
                    ...filterParams,
                    dateFrom: startDate,
                    dateTo: endDate,
                    month: nepaliMonths[monthIndex - 1]
                });
                break;
            } catch (error) {
                monthEnd--;
            }
        }
    };
    return (
        <>
        {isVisible &&
        <div className="fixed flex inset-0 bg-opacity-30 backdrop-blur-sm justify-center max-sm:items-start items-center">
            <div className="bg-white rounded-[10px] shadow p-5 w-[22rem]">
                <div className="text-zinc-900 text-[24px] font-semibold font-kantipur leading-[30px] mb-4">{'lkmn\\6/'}</div>
                <div className="border border-zinc-400/10"/>

                <div className="mt-5 space-y-4">
                    <div className="text-zinc-900 text-[20px] font-semibold font-kantipur leading-normal">{"dlxgfsf] cGt/fn"}</div>
                    {/* -----------------options--------------------- */}

                    <div className="flex flex-wrap gap-4">
                        <select id="BSYear" className='w-[160px] border p-2 rounded' onChange={handleYearChange}>
                            <option value="">--fiscal Year--</option>
                            {getFiscalYears().map((year, index) => (
                                <option key={index} value={year}>{year}</option>
                            ))}
                        </select>
                    </div>
                    <div className="flex flex-wrap gap-4">
                        <select id="from_month" className='w-[160px] border p-2 rounded' onChange={handleMonthChange}>
                            <option value="">--select Month--</option>
                            {nepaliMonths.map((month, index) => (
                                <option key={index} value={index}>{month}</option>
                            ))}
                        </select>
                    </div>
                </div>
                {/* <div className="mt-5 space-y-4">
                    <div className="text-zinc-900 text-[20px] font-semibold font-kantipur leading-normal">{'ldltsf] cGt/fn'}</div>
                    {/* -----------------options--------------------- 

                    <div className="flex flex-wrap gap-4">
                        <ReactDatePicker
                                id="dateFrom"
                                selected={filterParams.dateFrom ? new Date(filterParams.dateFrom) : null}
                                onChange={handleDateFromChange}
                                placeholderText="Date From" // Placeholder text for the date picker
                                className="w-[100px] border p-2 rounded"
                            />
                        <ReactDatePicker
                                id="dateTo"
                                selected={filterParams.dateTo ? new Date(filterParams.dateTo) : null}
                                onChange={handleDateToChange}
                                placeholderText="Date To" // Placeholder text for the date picker
                                className="w-[100px] border p-2 rounded"
                            />
                    </div>
                </div> */}


                <div className="flex gap-2.5 pt-3 justify-center pr-10">
        
                    <button className="px-8 py-3 rounded-[10px] border border-zinc-400/20 justify-center items-center inline-flex text-zinc-900 text-sm font-normal font-['Poppins']" 
                    onClick={hide}>Cancel</button>
                    <button className="px-10 py-3 bg-red-700 rounded-[10px] justify-center items-center inline-flex text-white text-sm font-normal font-['Poppins']"
                    onClick={handleApplyFilter}>Apply</button>

                </div>


            </div>
        </div>
        }
        </>
    )
})

export default FilterModal2
