export interface Noti {
        msg:string,
        status:string
}

const NotificationBar:React.FC<{noti:Noti}>= ({ noti }) => {
    const notificationStyle = noti.msg ? { display: 'flex' } : { display: 'none' };
    const baseStyle = "fixed top-5 right-5 w-[300px] rounded-lg p-4 shadow-lg transition-all duration-300";
    const successStyle = "bg-green-500 text-white";
    const failureStyle = "bg-orange-500 text-white";

    return (
        <div style={notificationStyle} className={`z-50 justify-end ${baseStyle} ${noti.status === 'success' ? successStyle : failureStyle}`}>
            <div className="flex justify-center w-full">
                <span className="text-lg font-semibold">{noti.msg}</span>
            </div>
        </div>
    );
};

export default NotificationBar;
