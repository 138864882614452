import React, { useState } from 'react'
import { UserService } from '../../../../Services/User.service'
import { useNavigate } from 'react-router-dom'
import NotificationBar from '../../../../Components/Ui/NotificationBar'

interface AddUserProps {
    userService : UserService
}
export const AddUser = ({userService}:AddUserProps) => {
    const navigate = useNavigate()
    const [noti,setNoti] = useState({msg:'',status:''})
    const [user, setUser] = useState({
        email:'',
        password : '',
        confirm_password : '',
        first_name : '',
        last_name : '',
        phone: ''
    })
    const token = localStorage.getItem('token')

    const handleCreate = async (e: React.MouseEvent<HTMLButtonElement>)=>{
        e.preventDefault()
        try{
            const formData = new FormData()
            formData.append('email', user.email)
            formData.append('password', user.password)
            formData.append('confirm_password', user.confirm_password)
            formData.append('first_name', user.first_name)
            formData.append('last_name', user.last_name)
            formData.append('phone', user.phone)

            userService.setToken(token)
    
            const response = await userService.createUser(formData)
            console.log(response)
            if(!response.data.errors){
                navigate(`/Settings/user-control`)
            }
            else {
            setNoti({msg:response.data.errors[0].title+':'+response.data.errors[0].description,status:'failure'})
            setTimeout(()=>{setNoti({msg:'',status:''})},2000)
            }
            
        } catch (error:any){
            console.error('error updating profile:',error)
        }
    }


  return (
    <>
        <div className='w-full mx-4 px-4 rounded-[10px] border border-zinc-400/20'>
        <form encType="multipart/form-data">
                <div className='flex flex-col w-full pt-7'>
                    <div className='flex flex-wrap w-full justify-between gap-5 mb-5'>
                        <div className='inline-flex items-center flex-1 min-w-[300px] rounded-[10px] border border-zinc-400/20 px-4 py-4'>
                            <input id='first_name' 
                            placeholder='First name' 
                            className='w-full outline-none'
                            onChange={(event)=>setUser({...user,first_name:event.target.value})}
                            />
                        </div>
                        <div className='flex-1 min-w-[300px] rounded-[10px] border border-zinc-400/20 px-4 py-4'>
                            <input id='last_name' 
                            placeholder='Last name' 
                            className='w-full outline-none'
                            onChange={(event)=>setUser({...user,last_name:event.target.value})}
                            />
                        </div>
                    </div>
                    <div className='flex flex-wrap w-full justify-between gap-5 mb-5'>
                        <div className='inline-flex items-center flex-1 min-w-[300px] rounded-[10px] border border-zinc-400/20 px-4 py-4'>
                            <input id='email' 
                            placeholder='Email'
                            className='w-full outline-none'
                            onChange={(event)=>setUser({...user,email:event.target.value})}
                            />
                        </div>
                        <div className='flex-1 min-w-[300px] rounded-[10px] border border-zinc-400/20 px-4 py-4'>
                            <input id='phone' 
                            placeholder='Phone'                            
                            className='w-full outline-none'
                            onChange={(event)=>setUser({...user,phone:event.target.value})}
                            />
                        </div>
                    </div>
                    <div className='flex flex-wrap w-full justify-between gap-5 mb-5'>
                        <div className='inline-flex items-center flex-1 min-w-[300px] rounded-[10px] border border-zinc-400/20 px-4 py-4'>
                            <input id='password' 
                            placeholder='Password'
                            className='w-full outline-none'
                            onChange={(event)=>setUser({...user,password:event.target.value})}
                            />                            
                        </div>
                        <div className='flex-1 min-w-[300px] rounded-[10px] border border-zinc-400/20 px-4 py-4'>
                            <input id='confirmPassword' 
                            placeholder='Confirm password'
                            className='w-full outline-none'
                            onChange={(event)=>setUser({...user,confirm_password:event.target.value})}
                            />
                        </div>
                    </div>
                    
                </div>
                {/* ---------------------------------------- Cancel Next button------------------------ */}
                <div className='flex justify-end'>
                    <NotificationBar noti={noti}/>
                    <div className='inline-flex gap-5 items-center'>
                        <div className='rounded-[10px] border border-zinc-400/20 p-3 text-zinc-900 text-base font-light font-lexend leading-normal h-full items-center cursor-pointer flex'
                        onClick={(e) => {
                            navigate('/Settings/user-control')
                        }}>Cancel</div>
                        <button type="submit" 
                        className='bg-red-700 rounded-[10px] py-3 px-5 text-white text-base font-light font-lexend leading-normal h-full'
                        onClick={handleCreate}
                        >Save</button>
                    </div>
                </div>
            </form>
        </div>

    </>
  )
}