import Dropdown from "../Components/Ui/Dropdown"

const Chart = () => {
  return (
    <>
      <div className="border flex-auto mobile:w-[95%] sm:w-full rounded border-zinc-300">
        <div className="flex justify-between items-center pt-6 px-12">
            <div className="flex text-[25px] font-medium font-kantipur leading-snug">
                {'pkl:ylt rf6{ '}
            </div>
            <Dropdown>{'of] xKtf'}</Dropdown>
        </div>
        <div>
            <div className="flex flex-row px-5 pt-11 gap-[4%]">
                <div className="flex flex-col justify-between">
                    <div className="text-zinc-900 text-sm font-light font-['Lexend'] leading-snug">100%</div>
                    <div className="text-zinc-900 text-sm font-light font-['Lexend'] leading-snug">80%</div>
                    <div className="text-zinc-900 text-sm font-light font-['Lexend'] leading-snug">60%</div>
                    <div className="text-zinc-900 text-sm font-light font-['Lexend'] leading-snug">40%</div>
                    <div className="text-zinc-900 text-sm font-light font-['Lexend'] leading-snug">20%</div>
                    <div className="text-zinc-900 text-sm font-light font-['Lexend'] leading-snug">0%</div>
                </div>
                <div className="inline-flex justify-between w-full py-1 px-6">
                    <div className="h-[400px] flex-col justify-start items-start gap-1.5 inline-flex">
                        <div className="w-3 bg-amber-300/70 rounded-[30px]" style={{ height: `0%` }} />
                        <div className="w-3 bg-red-200 rounded-[30px]" style={{ height: `20%` }} />
                        <div className="w-3 bg-green-300 rounded-[30px]" style={{ height: `80%` }} />
                    </div><div className="h-[400px] flex-col justify-start items-start gap-1.5 inline-flex">
                        <div className="w-3 bg-amber-300/70 rounded-[30px]" style={{ height: `60%` }} />
                        <div className="w-3 bg-red-200 rounded-[30px]" style={{ height: `20%` }} />
                        <div className="w-3 bg-green-300 rounded-[30px]" style={{ height: `20%` }} />
                    </div>
                    <div className="h-[400px] flex-col justify-start items-start gap-1.5 inline-flex">
                        <div className="w-3 bg-amber-300/70 rounded-[30px]" style={{ height: `50%` }} />
                        <div className="w-3 bg-red-200 rounded-[30px]" style={{ height: `30%` }} />
                        <div className="w-3 bg-green-300 rounded-[30px]" style={{ height: `20%` }} />
                    </div>
                    <div className="h-[400px] flex-col justify-start items-start gap-1.5 inline-flex">
                        <div className="w-3 bg-amber-300/70 rounded-[30px]" style={{ height: `50%` }} />
                        <div className="w-3 bg-red-200 rounded-[30px]" style={{ height: `30%` }} />
                        <div className="w-3 bg-green-300 rounded-[30px]" style={{ height: `20%` }} />
                    </div>
                    <div className="h-[400px] flex-col justify-start items-start gap-1.5 inline-flex">
                        <div className="w-3 bg-amber-300/70 rounded-[30px]" style={{ height: `50%` }} />
                        <div className="w-3 bg-red-200 rounded-[30px]" style={{ height: `30%` }} />
                        <div className="w-3 bg-green-300 rounded-[30px]" style={{ height: `20%` }} />
                    </div>
                    <div className="h-[400px] flex-col justify-start items-start gap-1.5 inline-flex">
                        <div className="w-3 bg-amber-300/70 rounded-[30px]" style={{ height: `50%` }} />
                        <div className="w-3 bg-red-200 rounded-[30px]" style={{ height: `30%` }} />
                        <div className="w-3 bg-green-300 rounded-[30px]" style={{ height: `20%` }} />
                    </div>
                    <div className="h-[400px] flex-col justify-start items-start gap-1.5 inline-flex">
                        <div className="w-3 bg-amber-300/70 rounded-[30px]" style={{ height: `50%` }} />
                        <div className="w-3 bg-red-200 rounded-[30px]" style={{ height: `30%` }} />
                        <div className="w-3 bg-green-300 rounded-[30px]" style={{ height: `20%` }} />
                    </div>
                </div>
            </div>
            <div className="flex flex-row px-5 pt-11 gap-[4%]">
                <div className="flex flex-col justify-between">
                    <div className="text-white/0 text-sm font-light font-['Lexend'] leading-snug">Days</div>
                </div>
                <div className="inline-flex justify-between w-full py-1 pl-3 pr-5">
                    <div className="text-center text-zinc-900 text-sm font-light font-['Lexend'] leading-snug">
                        Mon                            
                    </div>
                    <div className="text-center text-zinc-900 text-sm font-light font-['Lexend'] leading-snug">
                        Tue                           
                    </div>
                    <div className="text-center text-zinc-900 text-sm font-light font-['Lexend'] leading-snug">
                        Wed                           
                    </div>
                    <div className="text-center text-zinc-900 text-sm font-light font-['Lexend'] leading-snug">
                        Thu                           
                    </div>
                    <div className="text-center text-zinc-900 text-sm font-light font-['Lexend'] leading-snug">
                        Fri                           
                    </div>
                    <div className="text-center text-zinc-900 text-sm font-light font-['Lexend'] leading-snug">
                        Sat                           
                    </div>
                    <div className="text-center text-zinc-900 text-sm font-light font-['Lexend'] leading-snug">
                        Sun                           
                    </div>                        
                </div>
            </div>
            <div className="flex justify-center gap-5 p-4">
                <div className="items-center gap-3.5 inline-flex">
                    <div className="w-[11px] h-[11px] bg-red-200 rounded-full" />
                    <div className="text-black text-xs font-normal font-['Lexend']">Absent Employees</div>
                </div>
                <div className="items-center gap-3.5 inline-flex">
                    <div className="w-[11px] h-[11px] bg-green-300 rounded-full" />
                    <div className="text-black text-xs font-normal font-['Lexend']">Present Employees</div>
                </div>
                <div className="items-center gap-3.5 inline-flex">
                    <div className="w-[11px] h-[11px] bg-amber-200 rounded-full" />
                    <div className="text-black text-xs font-normal font-['Lexend']">On a Leave</div>
                </div>
            </div>
            
        </div>
      </div>
    </>
  )
}

export default Chart
