import { useEffect, useRef, useState } from "react"
import { Route, Routes, useNavigate, useParams } from "react-router-dom"
import { EmployeeData } from "../../../models/Employee"
import { EmployeeService } from "../../../Services/Employee.service"
import { SlBriefcase } from "react-icons/sl"
import { HiOutlineEnvelope } from "react-icons/hi2"
import { BsFillPersonFill } from "react-icons/bs"
import { IoPersonOutline } from "react-icons/io5"
import { BiSolidCalendarCheck } from "react-icons/bi"
import { BiCalendarCheck } from "react-icons/bi"
import { PiNotepadLight } from "react-icons/pi"
import { MdOutlineEventNote, MdOutlineLocalPrintshop } from "react-icons/md"
import { AiOutlineEdit } from "react-icons/ai"
import EmployeeDetailView from "./EmployeeDetailView"
import AttendanceDetail from "../AttendanceDetail"
import FilterModal2, { FilterModalHandle } from "../../../Partials/FilterModal2"
import { useReactToPrint } from "react-to-print"

type SelectionProps = {
    employeeService : EmployeeService
}
export const Selection = ({employeeService}:SelectionProps) => {
    const [selectedOption, setSelectedOption] = useState('profile')
    const [employee,setEmployee] = useState<EmployeeData>()
    const navigate = useNavigate()
    const {employeeId} = useParams()
    const token = localStorage.getItem('token')

    const [filterParams, setFilterParams] = useState({
        dateFrom: '',
        dateTo: '',
        month: '',
    });

    useEffect(() => {
        const fetchData = async () => {
            try{
                employeeService.setToken(token)
                if (employeeId) {
                const res = await employeeService.getEmployeeProfile(employeeId)
                setEmployee(res.data)
            }
            } catch (exception) {
                console.log(exception)
            }
        }
        fetchData()
    },[employeeId, token])

    const filterAttendanceRef = useRef<FilterModalHandle>(null)
    const showFilterModal = () => {
        if (filterAttendanceRef.current) {
          filterAttendanceRef.current.show();
        }
      }

    return (
        <>
        <div className='w-full mx-4'>
            <FilterModal2 ref={filterAttendanceRef} filterParams={filterParams} setSelectedOption={setSelectedOption} setFilterParams={setFilterParams}/>
            <div className='flex items-end flex-wrap justify-between border-b pr-[40px] pb-[30px] gap-2 max-sm:w-[94%]'>
                <div className='justify-start items-start gap-4 inline-flex'>
                    <div className="w-[100px] h-[100px]"><img className='w-[100px] h-[100px] rounded-[10px]' alt='employeeImage' src={employee?.image}/></div>
                    
                    <div>
                        <div className="text-2xl font-semibold font-lexend leading-9">
                            {employee?.name}
                        </div>
                        <div className='flex-col justify-start items-start gap-2 inline-flex'>
                            <div className='inline-flex gap-2.5 items-center'>
                                <SlBriefcase/>
                                <p className="text-base font-light font-lexend leading-normal">{employee?.position}</p>
                            </div>
                            <div className='inline-flex gap-2.5 items-center'>
                                <HiOutlineEnvelope/>
                                <p className="text-base font-light font-lexend leading-normal">{employee?.email}</p>
                            </div>
                        </div>
                    </div>                    
                </div>

                <div className="w-[190px] h-[50px] p-5 bg-indigo-50 rounded-[10px] justify-center items-center gap-2.5 inline-flex">
                    <div className="text-indigo-500 text-[18px] font-light font-kantipur leading-normal">{'a}7ssf] nfuL cg\'/f]w'}</div>
                </div>
                <div className="w-[165px] h-[50px] p-5 bg-red-700 rounded-[10px] justify-center items-center gap-2.5 inline-flex">
                    <AiOutlineEdit className="w-6 h-6 p-[3px] justify-center items-center flex text-white"/>
                    <div className="text-white text-[20px] font-light font-kantipur leading-[20px] cursor-pointer"
                    onClick={()=>{                                                                            
                        navigate(`/allemployees/${employeeId}/edit`)
                        localStorage.setItem('titlebar',JSON.stringify(
                          {
                              title: ';a} sd{rf/Lx?',
                              subhead : ';a} sd{rf/Lx? ÷ sd{rf/L ljj/0f kl/jt{g'
                            })
                        )   
                        }}>{'sd{rf/L ljj/0f kl/jt{g'}</div>
                </div>
            
            </div>

            <div className='flex flex-wrap mt-8 gap-4 max-sm:w-[92%]'>
                
                <div className='xl:flex-col flex flex-row max-h-[190px] xl:w-[160px] overflow-hidden max-sm:overflow-scroll rounded-[10px] border border-zinc-400/20'>
                    <div className={`inline-flex gap-2.5 items-center justify-start px-5 py-2 w-60 hover:cursor-pointer ${
                            selectedOption === 'profile' ? 'bg-secondRed' : ''
                        } hover:cursor-pointer`}
                    onClick={() => {
                        setSelectedOption('profile')
                        navigate('')}}>
                        { selectedOption === 'profile' ? <BsFillPersonFill className='w-5 h-5 rounded-full text-white'/>:<IoPersonOutline className='w-5 h-5'/>}
                        <div className={`${selectedOption === 'profile' 
                        ?'text-white text-[20px] font-semibold font-kantipur leading-normal'
                        :'text-zinc-900 text-[20px] font-light font-kantipur leading-normal'}`}>{'k|f]kmfOn'}</div>
                    </div>
                    <div className={`inline-flex gap-2.5 justify-start px-5 py-2 w-60 hover:cursor-pointer ${
                            selectedOption === 'attendance' ? 'bg-secondRed' : ''
                        }`}
                    onClick={() => {
                        setSelectedOption('attendance')
                        navigate(`attendance-detail`)
                    }}>
                        {selectedOption === 'attendance' ? <BiSolidCalendarCheck className='w-5 h-5 rounded-full text-white'/> : <BiCalendarCheck className='w-5 h-5'/>}
                        <div className={`${selectedOption === 'attendance' 
                        ?'text-white text-[20px] font-semibold font-kantipur leading-normal'
                        :'text-zinc-900 text-[20px] font-light font-kantipur leading-normal'}`}>{'pkl:ytL'}</div>
                    </div>
                    <div className={`inline-flex gap-2.5 justify-start items-center px-5 py-2 w-60 hover:cursor-pointer ${
                            selectedOption === 'project' ? 'bg-secondRed' : ''
                        }`}
                    onClick={() => {
                        setSelectedOption('project')
                        showFilterModal()
                        }}>
                        <PiNotepadLight/>
                        <div className={`${selectedOption === 'project' 
                        ?'text-white text-[20px] font-semibold font-kantipur leading-normal'
                        :'text-zinc-900 text-[20px] font-light font-kantipur leading-normal'}`}>{'dfl;s l/kf]6{'}</div>
                    </div>
                    <div className={`inline-flex gap-2.5 justify-start items-center px-5 py-2 w-60 hover:cursor-pointer ${
                            selectedOption === 'leave' ? 'bg-secondRed' : ''
                        }`}
                    onClick={() => setSelectedOption('leave')}>
                        <MdOutlineEventNote/>
                        <div className={`${selectedOption === 'leave' 
                        ?'text-white text-[20px] font-semibold font-kantipur leading-normal'
                        :'text-zinc-900 text-[20px] font-light font-kantipur leading-normal'}`}>{"5'QL"}</div>
                    </div>
                </div>
                <div className='flex-1 max-sm:w-[92%]'>
                    {/* --------------------------------------Content----------------------------------- */}
                    
                    <Routes>
                        <Route path='/' element={<EmployeeDetailView employee={employee}/>}/>
                        <Route path='/attendance-detail' element={<AttendanceDetail filterParams={filterParams}/>}/>

                        {/* <Route path='/attendance-detail' element={<AttendanceDetail employee={employee}/>}/> */}
                    </Routes>
                    
                </div>

            </div>
        </div>
        </>
    )
}