import React, { useState } from 'react'
import { UserService } from '../../../Services/User.service'
import { useNavigate } from 'react-router-dom'

type ChangePasswordProps = {
    userService : UserService
}

export const ChangePassword = ({userService}:ChangePasswordProps) => {
    const [password,setPassword] = useState<string>('')
    const [repassword,setRepassword] = useState<string>('')
    const navigate = useNavigate()
    const handleChangePassword = async (e: React.MouseEvent<HTMLButtonElement>)=>{
        e.preventDefault()
        try{

            
            userService.setToken(localStorage.getItem('token'))
            const passwordStatus = await userService.changePassword({
                password: password,
                repassword: repassword
            })
            // console.log(passwordStatus.data.errors[0].description)
            if (passwordStatus.msg) {
                navigate('/settings/change-password', { state: { noti: passwordStatus.msg } })
                setPassword('')
                setRepassword('')
            } 
            else {
                const noti=passwordStatus.data.errors[0].description
                navigate('/settings/change-password', { state: { noti: noti, status: 'error' } })
            }
            

            

        } catch (exception) {
            throw (exception)
        }
    }

  return (
    <>
      <div className="w-full rounded-[10px] border border-gray-200 mx-4 px-9 pt-[36px]">
                <div className="flex gap-[30px] mb-[34px] overflow-hidden">
                    <div className="text-red-700 text-[16px] font-normal font-kantipur leading-snug mt-2 w-[145px]">
                        {'नयाँ पासवोर्ड'}
                    </div>
                    <div className="flex flex-initial w-[300px] h-10 rounded-[10px] border border-zinc-600/30 items-center px-2.5">
                        <input id="password" className="outline-none h-full w-full"
                        value={password || ''}
                        onChange={(event)=>setPassword(event.target.value)}/>
                    </div>
                </div>
                <div className="flex gap-[30px] mb-[34px] overflow-hidden">
                    <div className="text-red-700 text-[16px] font-normal font-kantipur leading-snug mt-2 w-[145px]">
                        {'पासवोर्ड दोहोर्याउनुहोस्'}
                    </div>
                    <div className="flex flex-initial w-[300px] h-10 rounded-[10px] border border-zinc-600/30 items-center px-2.5">
                        <input id="repassword" className="outline-none h-full w-full"
                        value={repassword || ''}
                        onChange={(event)=>setRepassword(event.target.value)}/>
                    </div>
                </div>
                <button type="submit" 
                        className='bg-red-700 rounded-[10px] py-3 px-5 text-white text-base font-light font-lexend leading-normal'
                        onClick={handleChangePassword}
                        >Save</button>
        </div>
    </>
  )
}