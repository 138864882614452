import React, { useEffect } from 'react';
import { useNavigate, Routes, Route, Navigate  } from 'react-router-dom';
import Home from './Pages/Home';
import Login from './Pages/Login/Login';
import Design0 from './Design0';
import { checkForInactivity, updateExpireTime } from './CustomFunctions/customFunctions';

const App = () => {
  const token = localStorage.getItem('token')
  const navigate = useNavigate()

  useEffect(()=>{
    if (token) {
      const tokenData = JSON.parse(atob(token.split('.')[1])); // Decode the token
      const tokenExpiration = tokenData.exp * 1000; // Convert expiration time to milliseconds

      if (tokenExpiration < Date.now()) {
        // Token has expired, navigate to login
        localStorage.clear();
        navigate('/login', { state: { noti: 'Token Expired', status: 'error' } })
      }
    }
  },[token,navigate])

  // set interval to check for inactivity
  useEffect(()=> {
    const interval = setInterval(()=>{
      checkForInactivity()
    },1000000)

    //clear interval on unmount
    return () => clearInterval(interval)
    // eslint-disable-next-line
  },[])

  useEffect(() => {
    updateExpireTime()

    //set event Listeners
    window.addEventListener("click",updateExpireTime)
    window.addEventListener("keypress",updateExpireTime)
    window.addEventListener("scroll",updateExpireTime)
    window.addEventListener("mousemove",updateExpireTime)

    //cleanup
    return () => {
      window.removeEventListener("click",updateExpireTime)
      window.removeEventListener("keypress",updateExpireTime)
      window.removeEventListener("scroll",updateExpireTime)
      window.removeEventListener("mousemove",updateExpireTime)
    }
  },[])

  return (
    <>
      <div>
        <Routes>
          <Route path='/*' element={!token ? <Navigate replace to='/login'/>:<Home/>}/>
          <Route path='/login/*' element={token ? <Navigate replace to='/'/> :<Login />} />
          <Route path='/design' element={<Design0/>}/>
        </Routes>
      </div>
    </>
  )
}

export default App;
