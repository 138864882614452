import React, { useRef, useState } from 'react';
import logoSymbol from '../assets/logosymbol.png';
import { Link } from 'react-router-dom';
import DashboardElement from '../Components/Ui/DashboardElement';
import { BsGrid } from 'react-icons/bs';
import { HiOutlineUserGroup } from "react-icons/hi2";
import { LuCalendarCheck } from 'react-icons/lu';
import { IoSettingsOutline } from 'react-icons/io5';
import { FaBurger } from 'react-icons/fa6';
import { TiTimesOutline } from "react-icons/ti";
import { FaLongArrowAltRight, FaLongArrowAltLeft } from "react-icons/fa";
import { TbLayoutSidebarLeftCollapse, TbLayoutSidebarRightCollapse } from "react-icons/tb";

const Dashboard: React.FC = () => {
    const storedTitleBarString = localStorage.getItem('titlebar');
    const isActive = storedTitleBarString ? JSON.parse(storedTitleBarString).title : null;
    const sidemenuRef = useRef<HTMLDivElement>(null);
    const [isCollapsed, setIsCollapsed] = useState(false);

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <div className={`flex-col sm:min-h-[700px] z-20 bg-[#e1e0e0] sm:flex sm:relative transition-width duration-300 ${isCollapsed ? 'sm:w-[50px]' : 'sm:w-[220px]'}`}>
            <div>
                <div className="hidden w-full justify-center sm:flex">
                    <div className="mt-8 mb-10">
                        <img className='h-[68px] w-[88px]' alt='logoSymbol' src={logoSymbol} />
                    </div>
                </div>
                <div className='flex justify-between items-center w-full sm:hidden'>
                    <img className='h-[34px] w-[44px] m-3' alt='logoSymbol' src={logoSymbol} />
                    <FaBurger
                        className='mr-2 hover:cursor-pointer'
                        onClick={() => {
                            if (sidemenuRef.current !== null) {
                                sidemenuRef.current.className = 'flex flex-col space-y-7 w-[210px] pb-8 absolute top-0 right-0 sm:relative bg-[#e1e0e0]';
                            }
                        }}
                    />
                </div>
                <div className='flex-col justify-center'>
                    <div ref={sidemenuRef} className={`flex flex-col space-y-7 items-center sm:w-[220px] pb-8 absolute top-0 right-0 sm:relative bg-[#e1e0e0] transition-all duration-300 ${isCollapsed ? 'sm:w-[50px]' : 'sm:w-[220px]'}`}>
                        <TiTimesOutline className='text-red-800 sm:hidden hover:cursor-pointer'
                            onClick={() => {
                                if (sidemenuRef.current !== null) sidemenuRef.current.className = 'hidden flex-col space-y-7 w-[210px] pb-8 absolute top-0 right-0 sm:relative bg-[#e1e0e0] sm:flex';
                            }} />
                        <Link to='/'
                            onClick={() => {
                                localStorage.setItem('titlebar', JSON.stringify(
                                    {
                                        title: '8\of;af]8{',
                                        subhead: '6f]nL / ;|f]t ljj/0f'
                                    }))
                            }}
                        >
                            <DashboardElement isActive={isActive === '8\of;af]8{' ? true : false} component={BsGrid} isCollapsed={isCollapsed}>{'8\of;af]8{'}</DashboardElement>
                        </Link>
                        <Link to='/allemployees'
                            onClick={() => {
                                localStorage.setItem('titlebar', JSON.stringify(
                                    {
                                        title: ';a} sd{rf/Lx?',
                                        subhead: ';a} sd{rf/Lsf hfgsf/L'
                                    }))
                            }}
                        >
                            <DashboardElement isActive={isActive === ';a} sd{rf/Lx?' ? true : false} component={HiOutlineUserGroup} isCollapsed={isCollapsed}>{';a} sd{rf/L'}</DashboardElement>
                        </Link>
                        <Link to='/attendance'
                            onClick={() => {
                                localStorage.setItem('titlebar', JSON.stringify(
                                    {
                                        title: 'pkl:ytL',
                                        subhead: 'pkl:yltsf hfgsf/L'
                                    }))
                            }}
                        >
                            <DashboardElement isActive={isActive === 'pkl:ytL' ? true : false} component={LuCalendarCheck} isCollapsed={isCollapsed}>{'pkl:ytL'}</DashboardElement>
                        </Link>
                        <Link to='/settings'
                            onClick={() => {
                                localStorage.setItem('titlebar', JSON.stringify(
                                    {
                                        title: ';]l6ªx¿',
                                        subhead: ';]l6ªsf hfgsf/L'
                                    }))
                            }}
                        >
                            <DashboardElement isActive={isActive === ';]l6ªx¿' ? true : false} component={IoSettingsOutline} isCollapsed={isCollapsed}>{';]l6ªx¿'}</DashboardElement>
                        </Link>
                        
                    </div>
                </div>
            </div>
            <div className={`flex ${isCollapsed ? 'justify-center':''}`}>
            <button
                    onClick={toggleCollapse}
                    className={`mt-4 p-2 bg-gray-300 rounded ${isCollapsed ? '':'ml-6'} absolute bottom-8`}
                >
                    {isCollapsed ? <FaLongArrowAltRight /> : <FaLongArrowAltLeft />}
            </button>
            </div>
        </div>
    );
};

export default Dashboard;
