import React, { useEffect, useState } from 'react'
import { UserData } from '../../../models/Login'
import PaginationSection, { PageData } from '../../../Components/PaginationSection'
import Searchbar from '../../../Components/Ui/Searchbar'
import AddButton from '../../../Components/Ui/AddButton'
import { useNavigate } from 'react-router-dom'
import { UserService } from '../../../Services/User.service'

type UserProps = {
  userService : UserService
}

export const Users = ({userService}:UserProps) => {
  const navigate = useNavigate()
  const [users, setUsers] = useState<UserData[]>([])
  const token = localStorage.getItem('token')

  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [pageData, setPageData] = useState<PageData>({ 
    page_size: 0,
    page_count: 1,
    total_items: 0,
    page_number: 1})

  const [searchInput, setSearchInput] = useState('')
  const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value)
    setPageSize(10) // Reset pageSize to 10 on search input change
    setPage(1) // Reset page to 1 on search input change
  }

    useEffect(() => {
      const fetchData = async () => {

          try{
            userService.setToken(token)
            if (!searchInput) {
            const res = await userService.getUsers(
              {page:page,
              pageSize:pageSize,
              name:''})
            const sortedUsers = res.data.alluser.sort((a:any, b:any) => a.id - b.id)
            setUsers(sortedUsers)
            setPageData(res.data.meta)
          }

            else 
            
            {
            const res = await userService.getUsers(
              {page:page,
              pageSize:pageSize,
              name:searchInput.toLowerCase()}
            )
            const sortedUsers = res.data.alluser.sort((a:any, b:any) => a.id - b.id)
            setUsers(sortedUsers)
            setPageData(res.data.meta)
            }
          } catch (exception) {
            console.log(exception)
          }
      }
      fetchData()
    },[searchInput ,page,pageSize,token,userService])

  return (
<>
        <div className='w-full rounded-[10px] border border-zinc-400/20 mx-4 max-sm:w-[95%]'>
         {/* --------------------filter section ------------------------- */}
          <div className='flex justify-between w-full px-4 pr-8 py-4 flex-wrap gap-2'>
            <div className='w-[400px]'>
              <Searchbar changeEvent={handleSearchInput}/>
            </div>

            <div className='flex gap-5 '>
              <AddButton clickEvent={() => navigate('/settings/user-control/add-user')}>
                {'gofF k|of]ust{f yKg\'xf]:f'}
              </AddButton>
            </div>
          </div>
          <div className='overflow-x-scroll'>
            {/* -------------------------------------table------------------------------- */}
            <div className='min-w-full w-full'>
              <div className='grid grid-cols-3 gap-2 px-4 py-4 border-b border-zinc-400/10' style={{ gridTemplateColumns: '1fr 1fr 1fr' }}>
                <div className="text-zinc-400 text-[22px] font-light font-kantipur leading-normal min-w-[150px]">{'k|of]ustf{sf] gfd '}</div>
                <div className="text-zinc-400 text-[22px] font-light font-kantipur leading-normal min-w-[180px]">{'Od]n'}</div>
                <div className="text-zinc-400 text-[22px] font-light font-kantipur leading-normal min-w-[150px]">{'kmf]g'}</div>
              </div>
              <div className=''>
                          {users.map((user,index) => (
                          <div key={index} className='grid grid-cols-3 gap-2 px-4 border-b border-zinc-400/10 items-center' style={{ gridTemplateColumns: '1fr 1fr 1fr' }}>
                              <div className="text-zinc-900 text-base font-light font-lexend leading-normal min-w-[150px]">{user.first_name + ' ' + user.last_name}</div>
                              <div className="text-zinc-900 text-base font-light font-lexend leading-normal min-w-[180px]">{user.email}</div>
                              <div className="text-zinc-900 text-base font-light font-lexend leading-normal min-w-[150px]">{user.phone}</div>                       
                          </div>
                          ))}
                          

              </div>
              {/* -----------------------------------Pagination Section--------------------------------- */}
              <PaginationSection
              page = {page}
              setPage = {setPage}
              pageSize={pageSize}
              setPageSize = {setPageSize}
              pageData = {pageData}/>
            </div>
          </div>
        </div>
      </>

  )
}