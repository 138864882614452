import { AttendanceService } from "./Services/Attendance.service"
import { EmployeeService } from "./Services/Employee.service"
import { UserService } from "./Services/User.service"
import { HttpAdapter } from "./adapters/HttpClient"

class DependencyContainer {
    private _dependencies = {}

    add<T> (key: symbol, dependency: T) {
        Object.defineProperty(this._dependencies, key,{value: dependency})
    }

    get<T>(key: symbol): T {
        return Object.getOwnPropertyDescriptor(this._dependencies, key)?.value
    }
}

const httpAdapter = new HttpAdapter({ baseUrl: 'http://103.140.0.164:8000/api'})
const httpAdapterforLogin = new HttpAdapter({baseUrl: 'http://103.140.0.164:8000'})
const userService = new UserService(httpAdapterforLogin)
const attendanceService = new AttendanceService(httpAdapter)
const employeeService = new EmployeeService(httpAdapter)

const dependencies = {
    UserService: Symbol('UserService'),
    AttendanceService: Symbol('AttendanceService'),
    EmployeeService: Symbol('EmployeeService')
}

const container = new DependencyContainer()
container.add(dependencies.UserService,userService)
container.add(dependencies.AttendanceService,attendanceService)
container.add(dependencies.EmployeeService,employeeService)

export {container, dependencies}
