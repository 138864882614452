import React, { Dispatch, SetStateAction, createContext, useEffect, useRef, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import Dashboard from '../Partials/Dashboard'
import DashboardSection from './Dashboard'

import AllEmployees from './AllEmployees'
import FilterModal, { FilterModalHandle } from '../Partials/FilterModal'
import Attendance from './Attendance'
import Settings from './Settings/Settings'
import Titlebar from '../Partials/Titlebar'
import AccessControl from './Settings/AccessControl'
import EditPermission from './Settings/EditPermission'
import Selection from './AllEmployees/Selection'
import ProfessionalInfoEdit  from './AllEmployees/Edit'
import NotificationBar, { Noti } from '../Components/Ui/NotificationBar'
import Users from './Settings/Users'
import AddUser from './Settings/Users/AddUser'
import ChangePassword from './Settings/ChangePassword'

type TitleStateType = {
  titleState : {
    title: string
    subhead : string
  },
  setTitleState: Dispatch<SetStateAction<{ title: string; subhead: string; }>>
}

export const TitleState = createContext<TitleStateType>({
  titleState : {
    // eslint-disable-next-line
    title: '8\of;af]8{',
    subhead : '6f]nL / ;|f]t ljj/0f'
  },
  setTitleState: ()=>{}
})

const Home:React.FC = () => {
  const [noti,setNoti] = useState<Noti>({msg:'',status:''})
  const location= useLocation()

  const filterAttendanceRef = useRef<FilterModalHandle>(null)
  const [titleState,setTitleState] = useState({
    // eslint-disable-next-line
    title: '8\of;af]8{',
    subhead : '6f]nL / ;|f]t ljj/0f'
  } )
  useEffect(
    ()=>{
      const storedTitleBar = localStorage.getItem('titlebar');
    if (storedTitleBar) {
        setTitleState(JSON.parse(storedTitleBar));
    }}
    // eslint-disable-next-line
    ,[localStorage.getItem('titlebar')]
  )
  
  useEffect(()=>{
    setNoti({msg:location.state?.noti,status:'success'})
    setTimeout(()=>{setNoti({msg:'',status:''})},2000)

  },[location.state?.noti])

  // const fetchProfile = async () => {
  //   const token = localStorage.getItem('token');
  //   try {
  //     console.log(token)
  //     const response = await axios.get(`http://localhost:8000/account/profile/`, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: token ? `Bearer ${token}` : '',
  //       }
  //     });

  //     if (!response) {
  //       throw new Error('Network response was not ok');
  //     }

  //     const data = await response;
  //     console.log(data)
      
  //   } catch (error:any) {
  //     console.log(error.message);
  //   }
  // };

  // useEffect(() => {
  //   fetchProfile();
  // }, []);

  return (<>
    <TitleState.Provider value={{
      titleState,setTitleState
    }}>
    <div className='flex flex-col min-h-min sm:flex-row w-full'>
      <Dashboard/>
      <div className='flex flex-col flex-1'>
        <NotificationBar noti={noti}/>
        <Titlebar/>
        <div className='flex flex-1'>
          <Routes>
              <Route path='/' element={<DashboardSection/>}/>
              <Route path='/allemployees/*'>
                <Route index element={<AllEmployees/>}/>
                <Route path=':employeeId/*' element={<Selection/>}/>
                <Route path=':employeeId/edit/*' element={<ProfessionalInfoEdit/>}/>
              </Route>
              <Route path='/attendance' element={<Attendance filterAttendanceRef={filterAttendanceRef}/>}/>
              <Route path='/Settings/*' >
                <Route index element={<Settings/>}/>
                <Route path='user-control' element={<Users/>}/>
                <Route path='user-control/add-user' element={<AddUser/>}/>
                <Route path='access-control' element={<AccessControl/>}/>
                <Route path='access-control/edit-permission' element={<EditPermission/>}/>
                <Route path='change-password' element={<ChangePassword/>}/>
              </Route>

          </Routes>
        </div>
      </div>
      <FilterModal ref={filterAttendanceRef}/>
    </div>
    </TitleState.Provider>
    </>
  )
}

export default Home
