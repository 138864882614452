import { HttpAdapter } from "../adapters/HttpClient";
import { GetAttendanceParams } from "../models/Attendance";

export class AttendanceService {
    private readonly http : HttpAdapter
    private token: string | null = null

    constructor(httpAdapter:HttpAdapter) {
        this.http = httpAdapter
    }

    setToken(token: string | null) {
        this.token = token;
    }

    async getAttendanceFromId({id,from_date,to_date}: {
        id:string | undefined,
        from_date:string,
        to_date:string 
        }):Promise<any> {
        try {
            const config = {
                headers: {
                    Authorization: this.token ? `Bearer ${this.token}` : '',
                    'Content-Type': 'application/json'
                }
            };
            // const res= await this.http.get<any>(`/employee/${id}/attendance?page=${page}&page_size=${pageSize}&from_date=${dateFrom}&to_date=${dateTo}`, config);
            const res= await this.http.get<any>(`/employee/${id}/attendance/reports?from_date=${from_date}&to_date=${to_date}`, config);
            return res
            // return res.data.employee.attendance
        } catch (error) {
            throw error; // Rethrow error
        }
    }
    
    async getAttendances({page,pageSize,name,dateFrom,dateTo}:GetAttendanceParams):Promise<any> {
        try {
            const config = {
                headers: {
                    Authorization: this.token ? `Bearer ${this.token}` : '',
                    'Content-Type': 'application/json'
                }
            };
            const res= await this.http.get<any>(`/attendance?page=${page}&page_size=${pageSize}&name=${name}&from_date=${dateFrom}&to_date=${dateTo}`, config);
            return res.data
        } catch (error) {
            throw error; // Rethrow error
        }
    }
}