import React, { useEffect, useMemo, useState } from 'react'
import { AttendanceLogwithEmployee } from '../models/Attendance'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6'

type MonthlyAttendanceProps = {
    attendanceLog : AttendanceLogwithEmployee
}

const MonthlyAttendance = ({attendanceLog}:MonthlyAttendanceProps) => {
    console.log(attendanceLog)
    const [pageSize, setPageSize] = useState(10)
    const [page, setPage] = useState(1)

    // Calculate page_count
    const pageCount = useMemo(() => {
        return Math.ceil(attendanceLog.attendance.length / pageSize);
    }, [attendanceLog.attendance.length, pageSize]);

    const [paginatedData, setPaginatedData] = useState(attendanceLog.attendance.slice(0, pageSize));

    useEffect(() => {
        const start = (page - 1) * pageSize;
        const end = start + pageSize;
        setPaginatedData(attendanceLog.attendance.slice(start, end));
    }, [page, pageSize, attendanceLog.attendance]);

    const visibleNumbers = [];
    for (let i = Math.max(1, Math.min(page, pageCount - 3)); i <= Math.min(page + 3, pageCount); i++) {
        visibleNumbers.push(i);
    }


    const handlePageSize = async (event:React.ChangeEvent<HTMLSelectElement>) => {
        setPageSize(parseInt(event.target.value,10))
        setPage(1)
    }

    const handleIncrement = () => {
        const nextPage = page + 1 <= pageCount ? page + 1 : page
        setPage(nextPage);
    }

    const handleDecrement = () => {
        const prevPage = page - 1 >= 1 ? page - 1 : 1
        setPage(prevPage);
    }
    const formatDayOfWeek = (dateString:string) => {
        const options: Intl.DateTimeFormatOptions = { weekday: 'long' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    }
    const formatTime = (timeString:string) => {
        if (!timeString) {
            return '---';
        }
        return new Date(timeString).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }
    const calculateWorkingHours = (checkin:string, checkout:string) => {
        const checkinTime = new Date(checkin);
        const checkoutTime = new Date(checkout);
    
        // Calculate the difference in milliseconds
        const diffMs = checkoutTime.getTime() - checkinTime.getTime();
    
        // Convert milliseconds to hours and minutes
        const hours = Math.floor(diffMs / (1000 * 60 * 60));
        const minutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
    
        // Format the result
        if (!checkout || !checkin) {
            return '---';
        }
        return `${hours}:${minutes.toString().padStart(2, '0')} hrs`
    }

  return (
    <>
        <div>
        {/* Render attendance log */}
        <div className='grid grid-cols-7 gap-2.5 mt-2 px-2 border-b border-zinc-400/10 items-center justify-start' style={{ gridTemplateColumns: '0.8fr 0.8fr 0.8fr 1fr 1fr 0.8fr 2fr' }}>
            <div className="text-zinc-400 text-[16px] font-light font-kantipur leading-snug min-w-[120px]">{'ldlt'}</div>
            <div className="text-zinc-400 text-[16px] font-light font-kantipur leading-snug min-w-[100px]">{'lbg'}</div>
            <div className="text-zinc-400 text-[16px] font-light font-kantipur leading-snug min-w-[100px]">{'r]s Og 6fOd'}</div>
            <div className="text-zinc-400 text-[16px] font-light font-kantipur leading-snug min-w-[100px]">{'r]s cfpt 6fOd'}</div>
            <div className="text-zinc-400 text-[16px] font-light font-kantipur leading-snug min-w-[100px]">{'sfd ug]{ ;do'}</div>
            <div className="text-zinc-400 text-[16px] font-light font-kantipur leading-snug min-w-[100px]">{'l:ylt'}</div>
            <div className="text-zinc-400 text-[16px] font-light font-kantipur leading-snug min-w-[100px]">{'s}lkmot'}</div>
        </div>
        {paginatedData.map((log, index) => (
            <div key={index} className='grid grid-cols-7 gap-2.5 px-4 py-1 border-b border-zinc-400/10 items-center justify-start' style={{ gridTemplateColumns: '0.8fr 0.8fr 0.8fr 1fr 1fr 0.8fr 2fr' }}>
                <div className="text-zinc-900 text-[12px] font-light font-lexend leading-none min-w-[120px]">{log.nepali_verify_date || log.nepali_data}</div>
                <div className="text-zinc-900 text-[12px] font-light font-lexend leading-none min-w-[100px]">{formatDayOfWeek(log.verify_date)}</div>
                <div className="text-zinc-900 text-[12px] font-light font-lexend leading-none min-w-[100px]">{log.status === 'present' ? formatTime(log.checkin_time) : '----'}</div>
                <div className="text-zinc-900 text-[12px] font-light font-lexend leading-none min-w-[100px]">{log.status === 'present' ? formatTime(log.checkout_time) : '----'}</div>
                <div className="text-zinc-900 text-[12px] font-light font-lexend leading-none min-w-[100px]">{log.status === 'present' ? calculateWorkingHours(log.checkin_time, log.checkout_time) : '----'}</div>
                <div className={`pr-2.5 py-0.5 justify-start items-start gap-2.5 inline-flex min-w-[100px] ${log.status === 'present' ? 'bg-emerald-400/10' : 'bg-red-300/10'} px-2 py-[3px] rounded justify-center items-center gap-2.5 flex`}>
                    <div className={`${log.status === 'present' ? 'text-emerald-400' : 'text-red-300'} text-[10px] font-light font-['Lexend'] leading-[14px]`}>{log.status}</div>
                </div>
                <div className="text-zinc-900 text-base font-light font-lexend leading-none min-w-[100px]">{log.remarks}</div>
            </div>
        ))}


        <div className='flex justify-between w-full px-4 pr-8 py-2 flex-wrap items-center'>
                  <div className='inline-flex items-center gap-5'>
                      <div className="text-zinc-400 text-[12px] font-light font-['Lexend'] leading-snug">Showing</div>
                      <div className='inline-flex items-center px-3 py-1 gap-4 rounded-[10px] border border-zinc-400/20'>
                          <select id="pageSize" onChange={handlePageSize} className='outline-none text-[12px]' value={pageSize}>
                              <option value='10'>10</option>
                              <option value='15'>15</option>
                              <option value='20'>20</option>
                          </select>
                      </div>
                  </div>
                  <div className='inline-flex items-center'>
                      <FaAngleLeft onClick={handleDecrement} className='hover:cursor-pointer'/>
                      <div className='inline-flex px-2 gap-2'>
                          {visibleNumbers.map((number) => (
                              <div key={number} 
                                  className={`text-center hover:cursor-pointer px-1.5 py-1 gap-2 font-lexend text-[12px] font-light leading-normal ${page === number ? 'border border-red-700 rounded-lg' : 'text-zinc-900'}`}
                                  onClick={() => setPage(number)}
                                  >
                                  {number}
                              </div>
                          ))}
                      </div>
                      <FaAngleRight onClick={handleIncrement} className='hover:cursor-pointer'/>
                  </div>
        </div>
        </div>

    </>
  )
}

export default MonthlyAttendance