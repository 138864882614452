import React, { ChangeEventHandler } from 'react'
import { Link } from 'react-router-dom'

type LoginFormProps = {
    handleLogin : (event: React.FormEvent<HTMLFormElement>) => Promise<void>
    emailChangeEvent : ChangeEventHandler<HTMLInputElement>
    passwordChangeEvent : ChangeEventHandler<HTMLInputElement>
}

const LoginForm = ({handleLogin,emailChangeEvent,passwordChangeEvent}:LoginFormProps) => {
 
  return (
    <>
        <form onSubmit={handleLogin} className="flex-col justify-start items-start gap-[30px] flex">
            <div className="flex-col justify-start items-end gap-4 flex">
                <div className="flex-col justify-start items-start gap-5 flex w-full">
                    <div className="h-[55px] px-4 py-[7px] bg-white rounded-[10px] border border-red-700 flex-col justify-start items-start gap-2.5 flex w-full">
                        <div className="self-stretch justify-start items-center gap-2.5 inline-flex">
                            <div className="grow shrink basis-0 flex-col justify-start items-start gap-px inline-flex">
                                <div className="self-stretch text-red-700 text-[11px] font-light font-['Lexend'] leading-none">Email Address</div>
                                <input id="Email1" 
                                onChange={emailChangeEvent} 
                                type="text" 
                                className="self-stretch text-zinc-900 text-base font-light font-['Lexend'] leading-normal outline-none" 
                                placeholder="admin@example.com"
                                required/>
                            </div>
                        </div>
                    </div>
                    <div className="h-[55px] px-4 py-[7px] bg-white rounded-[10px] border border-red-700 flex-col justify-start items-start gap-2.5 flex w-full">
                        <div className="self-stretch justify-start items-center gap-2.5 inline-flex">
                            <div className="grow shrink basis-0 flex-col justify-start items-start gap-px inline-flex">
                                <div className="self-stretch text-red-700 text-[11px] font-light font-['Lexend'] leading-none">Password</div>
                                <input id="Password1" 
                                onChange={passwordChangeEvent} 
                                type="password" 
                                className="self-stretch text-zinc-900 text-base font-light font-['Lexend'] leading-normal outline-none" 
                                placeholder="************"
                                required/>
                            </div>
                        </div>
                        <div className="w-6 h-6 justify-center items-center flex">
                            <div className="w-6 h-6 relative">
                            </div>
                        </div>
                    </div>
                </div>

                <div className="self-stretch justify-start items-center gap-[150px] inline-flex">
                    <div className="pr-4 justify-center items-center gap-2.5 flex">
                        <input id="formControlInputCheckbox" onChange={(e)=>{console.log(e.target.checked)}} type="checkbox" className="w-6 h-6 accent-primary" />
                        <div className="text-zinc-900 text-base font-light font-['Lexend'] leading-normal">Remember Me</div>
                    </div>
                    <Link to='forgotpage'>
                        <div className="text-right text-red-700 text-sm font-light font-['Lexend'] leading-snug">Forgot Password?</div>
                    </Link>
                </div>

            </div>

            <div className="self-stretch h-14 justify-start items-start inline-flex">
                <div className="w-full h-14 p-5 bg-red-700 rounded-[10px] justify-center items-center gap-2.5 flex">    
                    <button type="submit" 
                    className="text-white text-base font-medium font-['Lexend'] leading-normal w-full">
                        Login
                    </button>             
                </div>
            </div>
            <div className="self-stretch grow shrink basis-0 text-center">
                <span className="text-black text-base font-light font-['Lexend'] leading-normal">Dont have an account? </span>
                <Link to='register'>
                    <div className="text-red-700 text-base font-semibold font-['Lexend'] leading-normal">Sign-Up</div>
                </Link>
            </div>
        </form>
    </>
  )
}

export default LoginForm
