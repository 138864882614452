import React from 'react';
import { EmployeeAttendanceData } from '../../models/Attendance';
import { FilterParams } from '../../Partials/FilterModal';

interface PrintTitleProps {

    employees: EmployeeAttendanceData[];
    filterParams:FilterParams
}

const PrintTitle: React.FC<PrintTitleProps> = ({ employees,filterParams }) => {

    const numberOfDays = (dateFrom:any, dateTo:any) => {
        const fromDate:any = new Date(dateFrom);
        const toDate:any = new Date(dateTo);
        const timeDifference = toDate - fromDate; // Difference in milliseconds
        const daysDifference = timeDifference / (1000 * 60 * 60 * 24); // Convert to days
        return daysDifference;
    };
    const weekends = (dateFrom: any, dateTo: any) => {
        const fromDate: any = new Date(dateFrom);
        const toDate: any = new Date(dateTo);
        let weekendsCount = 0;
        
        // Loop through each day between dateFrom and dateTo
        for (let d = new Date(fromDate); d <= toDate; d.setDate(d.getDate() + 1)) {
            if (d.getDay() === 6) { // Sunday (0) or Saturday (6)
                weekendsCount++;
            }
        }
        
        return weekendsCount;
    };
    const absentDays = (dateFrom: any, dateTo: any, attendanceRecords: EmployeeAttendanceData[]) => {
        const fromDate: any = new Date(dateFrom);
        const toDate: any = new Date(dateTo);
        let absentCount = 0;

        // Create a set of dates with attendance records
        const attendanceDates = new Set(attendanceRecords.map(record => new Date(record.checkin_time).toDateString()));
        console.log(attendanceDates)
        // Loop through each day between dateFrom and dateTo
        for (let d = new Date(fromDate); d <= toDate; d.setDate(d.getDate() + 1)) {
            if (d.getDay() !== 6 && !attendanceDates.has(d.toDateString())) { // Not Saturday and no attendance
                absentCount++;
                console.log(d)
            }
        }

        return absentCount;
    };
    const days = numberOfDays(filterParams?.dateFrom,filterParams?.dateTo)
    const wkends = weekends(filterParams?.dateFrom,filterParams?.dateTo)
    const absents = absentDays(filterParams?.dateFrom, filterParams?.dateTo, employees || []);

    return (
        <div>
            <div className='text-center text-xl font-bold py-4'>
                Monthly Ledger Report
            </div>
            <div className='text-center text-lg '>Fiscal Year: Fiscal Year 2080</div>
            <div className='flex mt-2 items-end justify-between'>
                <div className='pl-4 items-end mb-2'>
                    <div>Month: Kartik</div>
                    <div>Staff Name: {employees[0]?.employee.name}</div>
                </div>
                <div className='mb-2'>
                    <div className='text-center text-lg font-bold '>Monthly Summary</div>
                    <div className='grid grid-cols-8 gap-2 px-4 py-4 border border-zinc-400/10' style={{ gridTemplateColumns: '1fr 1fr 1fr 0.7fr 1.2fr 0.7fr 1fr 1fr' }}>
                        <div className="text-sm font-light leading-normal min-w-[40px]">Total Days</div>
                        <div className="text-sm font-light leading-normal min-w-[40px]">Weekend</div>
                        <div className="text-sm font-light leading-normal min-w-[40px]">Holiday</div>
                        <div className="text-sm font-light leading-normal min-w-[40px]">Leave</div>
                        <div className="text-sm font-light leading-normal min-w-[40px]">Working Days</div>
                        <div className="text-sm font-light leading-normal min-w-[40px]">Absent</div>
                        <div className="text-sm font-light leading-normal min-w-[40px]">Present Days</div>
                        <div className="text-sm font-light leading-normal min-w-[40px]">Count Days</div>
                    </div>
                    <div className='grid grid-cols-8 gap-2 px-4 py-4 border-b border-r border-l border-zinc-400/10' style={{ gridTemplateColumns: '1fr 1fr 1fr 0.7fr 1.2fr 0.7fr 1fr 1fr' }}>
                        <div className="text-sm font-light leading-normal min-w-[40px]">{days}</div>
                        <div className="text-sm font-light leading-normal min-w-[40px]">{wkends}</div>
                        <div className="text-sm font-light leading-normal min-w-[40px]">0</div>
                        <div className="text-sm font-light leading-normal min-w-[40px]">N/A</div>
                        <div className="text-sm font-light leading-normal min-w-[40px]">{days-wkends}</div>
                        <div className="text-sm font-light leading-normal min-w-[40px]">{absents}</div>
                        <div className="text-sm font-light leading-normal min-w-[40px]">{days-absents-wkends}</div>
                        <div className="text-sm font-light leading-normal min-w-[40px]">{days-absents-wkends}</div>
                    </div>

                
                </div>
            </div>
        </div>
        
    );
};

export default PrintTitle;