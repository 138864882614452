import { HttpAdapter } from "../adapters/HttpClient";
import { EmployeesResponse, GetEmployeesParams } from "../models/Employee";

export class EmployeeService {
    private readonly http : HttpAdapter
    private token: string | null = null

    constructor(httpAdapter:HttpAdapter) {
        this.http = httpAdapter
    }

    setToken(token: string | null) {
        this.token = token;
    }

    async getEmployees({page,pageSize}:GetEmployeesParams):Promise<EmployeesResponse> {
        try {
            const config = {
                headers: {
                    Authorization: this.token ? `Bearer ${this.token}` : '',
                    'Content-Type': 'application/json'
                }
            };
            const res= await this.http.get<EmployeesResponse>(`/employee/?page=${page}&page_size=${pageSize}`, config);
            return res
        } catch (error) {
            throw error; // Rethrow error
        }
    }
    async filterEmployees ({name,page,pageSize}:{name:string,page:number,pageSize:number}):Promise<any> {
        try{
            const config = {
                headers: {
                    Authorization: this.token ? `Bearer ${this.token}` : '',
                    'Content-Type': 'application/json'
                }
            }
            const res = await this.http.get<any>(`/employee/?name=${name}&page=${page}&page_size=${pageSize}`,config)
            return (res.data)
        } catch (error:any) {
            throw new Error(error)
        }
    }
    
    async getEmployeeProfile(id:string|undefined):Promise<any> {
        try {
            const config = {
                headers: {
                    Authorization: this.token ? `Bearer ${this.token}` : '',
                    'Content-Type': 'application/json'
                }
            };
            const res= await this.http.get<any>(`/employee/${id}`, config);
            return res
        } catch (error) {
            throw error; // Rethrow error
        }
    }

    async updateEmployeeProfile(formdata:FormData,id:string|undefined):Promise<any> {
        try {
            const config = {
                headers: {
                    Authorization: this.token ? `Bearer ${this.token}` : '',
                    'Content-Type': 'multipart/form-data'
                }
            };
            const res= await this.http.put<any>(`/employee/${id}`, formdata, config);
            return res
        } catch (error:any) {
            return error.response.data.details[0].errors[0].message
        }
    }

    async getEmployeePresentAbsentView():Promise<any> {
        try {
            const config = {
                headers: {
                    Authorization: this.token ? `Bearer ${this.token}` : '',
                    'Content-Type': 'application/json'
                }
            };
            const res= await this.http.get<any>(`/dashboard`, config);
            return res
        } catch (error) {
            throw error; // Rethrow error
        }
    }

}