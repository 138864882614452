import { ChangeEventHandler } from 'react';
import { LuSearch } from "react-icons/lu";

type SearchbarProps = {
  changeEvent:ChangeEventHandler<HTMLInputElement>
}
const Searchbar = ({changeEvent}:SearchbarProps) => {
  return (
    <>
        <div className="flex-initial w-[330px] mobile:w-[300px] h-[50px] px-4 py-[13px] rounded-[10px] border border-gray-200 flex-col justify-center items-start gap-2.5 inline-flex">
            <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
                <LuSearch className="w-6 h-6 p-[1.25px] justify-center items-center flex text-black/50" />
                <input className="
                grow shrink text-base font-light font-lexend placeholder:font-kantipur placeholder:text-[20px] leading-normal
                outline-none"
                id="searchInput1"
                onChange={changeEvent}
                placeholder="vf]hL ug{'xf];"/>
            </div>
        </div>
    </>
  )
}

export default Searchbar

// Usage
// <Searchbar changeEvent={handleChange} />
// const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
//     console.log(e.target.value);
//   };
