import { useEffect, useState } from "react"
import { EmployeeData } from "../../models/Employee"
import PaginationSection, { PageData } from "../../Components/PaginationSection"
import { EmployeeService } from "../../Services/Employee.service"
import AddButton from "../../Components/Ui/AddButton"
import Searchbar from "../../Components/Ui/Searchbar"
import { LuEye } from "react-icons/lu"
import { FiEdit3 } from "react-icons/fi"
import { RiDeleteBin6Line } from "react-icons/ri"
import { useNavigate } from "react-router-dom"
import placeholderImage from "../../assets/placeholderImage.png"

type AllEmployeesProps = {
  employeeService : EmployeeService
}

export const AllEmployees = ({employeeService}: AllEmployeesProps) => {
    const navigate = useNavigate()
    // -------------------Handling Fetching Employees---------------------
    const [employees, setEmployees] = useState<EmployeeData[]>([])
    const token = localStorage.getItem('token')

    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [pageData, setPageData] = useState<PageData>({ 
      page_size: 0,
      page_count: 0,
      total_items: 0,
      page_number: 1})

    const [searchInput, setSearchInput] = useState('')

    useEffect(() => {
      const fetchData = async () => {

          try{
            employeeService.setToken(token)
            if (!searchInput) {
            const res = await employeeService.getEmployees(
              {page:page,
              pageSize:pageSize})
            const sortedEmployees = res.data.employee_data.sort((a, b) => a.id - b.id)
            setEmployees(sortedEmployees)
            setPageData(res.data.meta)}

            else 
            
            {
            const res = await employeeService.filterEmployees(
              {page:page,
              pageSize:pageSize,
              name:searchInput.toLowerCase()}
            )
            const sortedEmployees = res.employee_data.sort((a:any, b:any) => a.id - b.id)
            setEmployees(sortedEmployees)
            setPageData(res.meta)
            }
          } catch (exception) {
            console.log(exception)
          }
      }
      fetchData()
  },[searchInput ,page,pageSize,token,employeeService])
  // ----------------------------------------------------Search-------------------------
  // useEffect(()=>{

  //   const fetchData = async () => {

  //       try{
  //           employeeService.setToken(token)
  //           const res = await employeeService.filterEmployees(
  //             {page:page,
  //             pageSize:pageSize,
  //             name:searchInput.toLowerCase()}
  //         )
  //           setEmployees(res.employee_data)
  //           setPageData(res.meta)
  //           } catch (exception) {
  //               throw (exception)
  //           }
  //       }
  //       fetchData()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   },[searchInput,page,pageSize,token])

  const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value)
    setPageSize(10) // Reset pageSize to 10 on search input change
    setPage(1) // Reset page to 1 on search input change
  }
  return (
    <>
    <div className='w-full rounded-[10px] border border-zinc-400/20 mx-4 max-sm:w-[95%]'>
     {/* --------------------filter section ------------------------- */}
      <div className='flex justify-between w-full px-4 pr-8 py-4 flex-wrap gap-2'>
        <div className='w-[400px]'>
          <Searchbar changeEvent={handleSearchInput}/>
        </div>

        {/* <div className='flex gap-5 '>
          <AddButton clickEvent={() => console.log('button clicked')}>
            Add New Employee
          </AddButton>
        </div> */}
      </div>
      <div></div>
      <div className="max-xl:overflow-x-scroll">
      {/* -------------------------------------table------------------------------- */}
      <div className="min-w-[95%] w-full">
        <div className='grid grid-cols-7 gap-2 px-4 py-2 border-b border-zinc-400/10' style={{ gridTemplateColumns: '0.7fr 1.6fr 0.5fr 0.8fr 0.8fr 0.5fr 0.5fr' }}>
          <div className="text-zinc-400 text-[22px] text-center font-light font-kantipur leading-normal min-w-[100px]">{'sd{rf/L cfO{8L'}</div>
          <div className="text-zinc-400 text-[22px] ml-8 font-light font-kantipur leading-normal min-w-[150px]">{'sd{rf/Lsf] gfd'}</div>
          <div className="text-zinc-400 text-[22px] font-light font-kantipur leading-normal min-w-[150px]">{'ljefu'}</div>
          <div className="text-zinc-400 text-[22px] font-light font-kantipur leading-normal min-w-[150px]">{'kbgfd'}</div>
          <div className="text-zinc-400 text-[22px] font-light font-kantipur leading-normal min-w-[150px]">{'k|sf/'}</div>
          <div className="text-zinc-400 text-[22px] font-light font-kantipur leading-normal min-w-[150px]">{'l:ylt'}</div>
          <div className="text-zinc-400 text-[22px] font-light font-kantipur leading-normal min-w-[150px]">{'sfo{'}</div>
        </div>
        <div className=''>
                    {employees.map((employee,index) => (
                    <div key={index} className='grid grid-cols-7 gap-2 px-4 border-b border-zinc-400/10 items-center' style={{ gridTemplateColumns: '0.7fr 1.6fr 0.5fr 0.8fr 0.8fr 0.5fr 0.5fr' }}>
                        <div className="text-zinc-900 text-center text-base font-light font-lexend leading-normal min-w-[100px]">{employee.id}</div>
                        <div className='h-11 pr-2.5 py-1 justify-start items-center gap-2.5 inline-flex min-w-[150px]'>
                            <img className="w-9 h-9 rounded-full" alt='employeeImage' src={employee.image? employee.image: placeholderImage} />
                            <div className="text-zinc-900 text-base font-light font-lexend leading-normal hover:cursor-pointer"
                            onClick={()=>{
                              navigate(`/allemployees/${employee.id}`)
                              localStorage.setItem('titlebar',JSON.stringify(
                                {
                                    title: 'All Employees',
                                    subhead : `All Employee > ${employee.name}`
                                  })
                            )                             
                            }
                          }>{employee.name}</div>
                        </div>
                        <div className="text-zinc-900 text-base font-light font-lexend leading-normal min-w-[150px]">{employee.department}</div>
                        <div className="text-zinc-900 text-base font-light font-lexend leading-normal min-w-[150px]">{employee.position}</div>
                        <div className="text-zinc-900 text-base font-light font-lexend leading-normal min-w-[150px]">Remote</div>
                        <div className="text-zinc-900 text-base font-light font-lexend leading-normal min-w-[150px]">
                            <div className="w-[78px] h-6 px-2 py-[3px] bg-indigo-500/10 rounded justify-center items-center gap-2.5 inline-flex">
                                <div className="text-indigo-500 text-xs font-light font-['Lexend'] leading-[18px]">{employee.privilege}</div>
                            </div>
                        </div>
                        <div className="text-zinc-900 text-base font-light font-lexend leading-normal min-w-[150px]">
                            <div className='inline-flex gap-2'>
                                <LuEye
                                  onClick={()=>{
                                    navigate(`/allemployees/${employee.id}`)
                                    localStorage.setItem('titlebar',JSON.stringify(
                                      {
                                          title: ';a} sd{rf/Lx?',
                                          subhead : `;a} sd{rf/Lx? ÷ `
                                        })
                                  )                             
                                  }
                                }
                                  className='cursor-pointer'/>         
                                <FiEdit3
                                  onClick={()=>{                                                                            
                                  navigate(`/allemployees/${employee.id}/edit`)
                                  localStorage.setItem('titlebar',JSON.stringify(
                                    {
                                        title: ';a} sd{rf/Lx?',
                                        subhead : ';a} sd{rf/Lx? ÷ '
                                      })
                                  )   
                                  }}
                                  className='cursor-pointer'/>                                  
                                <RiDeleteBin6Line/>
                            </div>
                        </div>
                    </div>
                    ))}
                    

        </div>
        {/* -----------------------------------Pagination Section--------------------------------- */}
        <PaginationSection
        page = {page}
        setPage = {setPage}
        pageSize={pageSize}
        setPageSize = {setPageSize}
        pageData = {pageData}/>
      </div>
      </div>
    </div>
  </>
  )
}
