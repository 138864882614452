import React, { useEffect, useState } from 'react'
import NotificationBar from '../../../Components/Ui/NotificationBar'
import { IoPersonOutline} from "react-icons/io5";
import { BsFillPersonFill} from "react-icons/bs";
import document from '../../../assets/document.svg'
import accountlock from '../../../assets/accountlock.svg'
import { useNavigate, useParams } from 'react-router-dom'
import { EmployeeCreate } from '../../../models/Employee'
import { EmployeeService } from '../../../Services/Employee.service'
import { FaAngleDown } from 'react-icons/fa6'
import placeholderImage from '../../../assets/placeholderImage.png'

interface ProfessionalInfoEditProps {
  employeeService : EmployeeService
}
export const ProfessionalInfoEdit = ({employeeService}:ProfessionalInfoEditProps) => {
  const [noti,setNoti] = useState({msg:'',status:''})
  const [selectedTab, setSelectedTab] = useState<string>('professional')
  const {employeeId} = useParams()
  const [employee, setEmployee] = useState<EmployeeCreate>({
    name: '',
    image: '',
    position: '',
    department: '',
    email: '',
    phone: '',
    privilege: '',
    card_number: '',
    password: '',
    is_enabled: false
})
  const navigate = useNavigate()
  const token = localStorage.getItem('token')

  useEffect(() => {
    const fetchData = async () => {

        try{
            employeeService.setToken(token)
            const res = await employeeService.getEmployeeProfile(employeeId)
            setEmployee(res.data)
        } catch (exception) {
            console.log(exception)
        }
    }
    fetchData()
  },[token, employeeId, employeeService])

//  -----------------------------Handling Image -----------------------------------
  const [preview,setPreview] =useState('')
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setEmployee((prevState) => {
        return {
          ...prevState,
          image: file,
        }});
      setPreview(URL.createObjectURL(file));
    }
  }

  const handleUpdate = async (e: React.MouseEvent<HTMLButtonElement>)=>{
    e.preventDefault()
    try{
      if (employee) {
        const formData = new FormData()
        formData.append('name', employee.name)
        formData.append('image', employee.image) // Append the image file
        formData.append('position', employee.position)
        formData.append('department', employee.department)
        formData.append('email', employee.email)
        formData.append('phone', employee.phone.toString())
        formData.append('privilege', employee.privilege)
        formData.append('card_number', employee.card_number.toString())
        formData.append('password', employee.password)
        formData.append('is_enabled', employee.is_enabled.toString())

        employeeService.setToken(localStorage.getItem('token'))

        const response = await employeeService.updateEmployeeProfile(formData, employeeId)
        console.log(employee,response)
        if(!response.data.errors){
            navigate(`/allemployees/${employeeId}`)
        }
        else {
        setNoti({msg:response.data.errors[0].title+':'+response.data.errors[0].description,status:'failure'})
        setTimeout(()=>{setNoti({msg:'',status:''})},2000)
        }
        }
        
    } catch (error:any){
        console.error('error updating profile:',error)
    }
}

  return(
    <>
        <div className='w-full mx-4 rounded-[10px] border border-zinc-400/20 max-sm:w-[94%]'>
            <div className='px-5 py-5'>
                {/* --------------------Heading------------------------- */}
                <div className="inline-flex items-center gap-5">
                    <div
                    className={`inline-flex items-center gap-2.5 pb-2 cursor-pointer ${
                        selectedTab === 'professional' ? 'border-b-4 border-secondRed' : ''
                    }`}
                    onClick={() => {setSelectedTab('professional')
                        navigate(`/allemployees/${employeeId}/edit`)}
                    }>
                        {selectedTab === 'professional' ? (
                            <BsFillPersonFill className='w-5 h-5 rounded-full text-secondRed' />
                        ) : (
                            <IoPersonOutline className='w-5 h-5' />
                        )}
                        <p className={`text-[20px] font-light font-kantipur leading-6 ${selectedTab === 'professional' ? 'text-red-700 text-[20px] font-semibold font-kantipur leading-normal':''}`}>{'Jofj;flos hfgsf/L'}</p>
                    </div>

                    <div
                    className={`inline-flex items-center gap-2.5 pb-2 cursor-pointer ${
                        selectedTab === 'documents' ? 'border-b-4 border-secondRed' : ''
                    }`}
                    onClick={() => setSelectedTab('documents')}
                    >
                        <img src={document} alt='documentIcon'/>
                        <p className="text-[20px] font-light font-kantipur leading-6 mobile:hidden">{'sfuhftx¿'}</p>
                    </div>

                    <div
                    className={`inline-flex items-center gap-2.5 pb-2 cursor-pointer ${
                        selectedTab === 'accountAccess' ? 'border-b-4 border-secondRed' : ''
                    }`}
                    onClick={() => setSelectedTab('accountAccess')}
                    >
                        <img src={accountlock} alt='lockIcon'/>
                        <p className="text-[20px] font-light font-kantipur leading-6 mobile:hidden">{"vftf kx'r"}</p>
                    </div>

                </div>
            <form encType="multipart/form-data">
                <div className='flex flex-col w-full pt-7'>
                    <div className='flex flex-wrap w-full justify-between gap-5 mb-5'>
                        <div className='bg-zinc-400/5 rounded-[10px] border border-zinc-400/20 cursor-pointer active:scale-90'>
                            <input id='imageUpload'
                            type="file"  
                            className='w-full'
                            onChange={handleImageChange}
                            style={{display:"none"}}/>
                            <label htmlFor="imageUpload" className="cursor-pointer">
                                <img src={preview? preview : placeholderImage || (typeof employee?.image === 'string' ? employee?.image : '')} className="h-20 w-20" alt='img'/>
                            </label>
                           
                        </div>
                    </div>
                    <div className='flex flex-wrap w-full justify-between gap-5 mb-5'>
                        <div className='flex-1 min-w-[300px] rounded-[10px] border border-zinc-400/20 px-4 py-4'>
                            <div>{employeeId}</div>
                        </div>
                        <div className='flex-1 min-w-[300px] rounded-[10px] border border-zinc-400/20 px-4 py-4'>
                            <input id='username' 
                            value={employee?.name} 
                            className='w-full outline-none'
                            onChange={(event)=>employee? setEmployee({...employee,name:event.target.value}): ''}
                            autoComplete="username"
                            />
                        </div>
                    </div>
                    <div className='flex flex-wrap w-full justify-between gap-5 mb-5'>
                        <div className='inline-flex items-center flex-1 min-w-[300px] rounded-[10px] border border-zinc-400/20 px-4 py-4'>
                            <input id='department' 
                            value={employee?.department} 
                            className='w-full outline-none'
                            onChange={(event)=>employee?setEmployee({...employee,department:event.target.value}):''}
                            />
                            <FaAngleDown className='text-zinc-500'/>
                        </div>
                        <div className='flex-1 min-w-[300px] rounded-[10px] border border-zinc-400/20 px-4 py-4'>
                            <input id='email' 
                            value={employee?.email} 
                            className='w-full outline-none'
                            onChange={(event)=>employee? setEmployee({...employee,email:event.target.value}): ''}
                            autoComplete="email"/>
                        </div>
                    </div>
                    <div className='flex flex-wrap w-full justify-between gap-5 mb-5'>
                        <div className='inline-flex items-center flex-1 min-w-[300px] rounded-[10px] border border-zinc-400/20 px-4 py-4'>
                            <input id='position' 
                            value={employee?.position} 
                            className='w-full outline-none'
                            onChange={(event)=>employee? setEmployee({...employee,position:event.target.value}):''}
                            />
                            <FaAngleDown className='text-zinc-500'/>
                        </div>
                        <div className='flex-1 min-w-[300px] rounded-[10px] border border-zinc-400/20 px-4 py-4'>
                            <input id='phone' 
                            value={employee?.phone}
                            className='w-full outline-none'
                            onChange={(event)=>employee? setEmployee({...employee,phone:event.target.value}):''}
                            autoComplete="phone"/>
                        </div>
                    </div>
                    <div className='flex flex-wrap w-full justify-between gap-5 mb-5'>
                        <div className='inline-flex items-center flex-1 min-w-[300px] rounded-[10px] border border-zinc-400/20 px-4 py-4'>
                            <input id='privilege' 
                            value={employee?.privilege} 
                            className='w-full outline-none'
                            onChange={(event)=>employee? setEmployee({...employee,privilege:event.target.value}):''}
                            />
                            <FaAngleDown className='text-zinc-500'/>
                        </div>
                        <div className='flex-1 min-w-[300px] rounded-[10px] border border-zinc-400/20 px-4 py-4'>
                            <input id='card_number' 
                            value={employee?.card_number}
                            className='w-full outline-none'
                            onChange={(event)=>employee?setEmployee({...employee,card_number:event.target.value}):''}
                            />
                        </div>
                    </div>
                    <div className='flex flex-wrap w-full justify-between gap-5 mb-5'>
                        <div className='inline-flex items-center flex-1 min-w-[300px] rounded-[10px] border border-zinc-400/20 px-4 py-4'>
                            <div className="inline-flex gap-4 items-center">
                                <input id='is_enabled' 
                                type="checkbox"
                                name="is_enabled"
                                className='outline-none w-4 h-4'
                                onChange={(event)=>{if (employee) {
                                    // const value = event.target.checked? 'True':'False'
                                    console.log(event.target.checked)
                                    setEmployee({...employee,is_enabled:event.target.checked})}
                                }}
                                checked={employee.is_enabled===true}/>
                                <div className="text-[#9f9595]">is_enabled</div>
                            </div>
                        </div>
                        <div className='flex-1 min-w-[300px] rounded-[10px] border border-zinc-400/20 px-4 py-4'>
                            <input id='password'
                            type="password"
                            value={employee?.password}
                            className='w-full outline-none'
                            onChange={(event)=>employee? setEmployee({...employee,password:event.target.value}):''}
                            />
                        </div>
                    </div>
                    
                </div>
                {/* ---------------------------------------- Cancel Next button------------------------ */}
                <div className='flex justify-end'>
                    <NotificationBar noti={noti}/>
                    <div className='inline-flex gap-5 items-center'>
                        <div className='rounded-[10px] border border-zinc-400/20 p-3 text-zinc-900 text-base font-light font-lexend leading-normal h-full items-center cursor-pointer flex'
                        onClick={(e) => {
                        navigate('/allemployees')
                        }}>Cancel</div>
                        <button type="submit" 
                        className='bg-red-700 rounded-[10px] py-3 px-5 text-white text-base font-light font-lexend leading-normal h-full'
                        onClick={handleUpdate}
                        >Save</button>
                    </div>
                </div>
            </form>
            </div>
          </div>
        </>
    )
}
