import { Link } from 'react-router-dom'
import SetNotificationSlider from '../../Components/Ui/SetNotificationSlider'

const Settings = () => {
  return(
      <>
      <div className='w-full'>
          <div className="rounded-2.5 border border-gray-200 space-y-6 px-6 py-6 mx-4" >
                <div className="py-3 px-6 rounded-lg border border-gray-200 flex-col justify-start items-start space-y-2 flex-1">
                  <Link to='/settings/user-control'
                  onClick={()=>{
                    localStorage.setItem('titlebar',JSON.stringify(
                        {
                            title: ';]l6ªx¿',
                            subhead : `;]l6ªx¿ ÷ k|of]ustf{ lgoGq0f `
                          })
                    ) 
                  }}>
                      <div className="text-black text-[20px] font-normal font-kantipur leading-snug">{'k|of]ustf{ lgoGq0f'}</div>                                   
                  </Link>
                  <div className="text-zinc-500/80 text-[19px] font-light font-kantipur leading-snug">{'k|of]ustf{x¿ l;h{gf ug{ / x]g'} </div>
                </div> 

                <div className="py-3 px-6 rounded-lg border border-gray-200 flex-col justify-start items-start space-y-2 flex-1">
                    <Link to='/settings/access-control'
                    onClick={()=>{
                        localStorage.setItem('titlebar',JSON.stringify(
                            {
                                title: ';]l6ªx¿',
                                subhead : `;]l6ª hfgsf/L ÷ kx'r lgoGq0f `
                            })
                        ) 
                    }}>
                        <div className="text-black text-[20px] font-normal font-kantipur leading-snug">{"kx'r lgoGq0f"}</div>                                   
                    </Link>
                    <div className="text-zinc-500/80 text-[19px] font-light font-kantipur leading-snug">{'sd{rf/L / k|zf;s aLr 8]6f kx\'r / e"ldsf gLltx¿ k|aGw ug{\'xf];\\'}</div>
                </div>  

            <SetNotificationSlider 
                notificationType= {'df]afOn k\'z ;"rgf'}
                recieveType={'k\'z ;"rgf k|fKt ug{\'xf];\\'}
                id={'formobile'}
                value = {'mobile'}
                changeEvent= {(e)=>console.log(e.target.value)}/>
            <SetNotificationSlider 
                notificationType= {'8]:s6k ;"rgf'}
                recieveType={'8]:s6kdf k\'z ;"rgf k|fKt ug{\'xf];\\'}
                id={'fordesktop'}
                value = {'desktop'}
                changeEvent= {(e)=>console.log(e.target.value)}/>
            <SetNotificationSlider 
                notificationType= {'Od]n ;"rgf'}
                recieveType={'Od]ndf ;"rgf k|fKt ug{\'xf];\\'}
                id = {'foremail'}
                value = {'email'}
                changeEvent= {(e)=>console.log(e.target.value)}/>

          </div>
      </div>
      </>
  )
}
export default Settings
