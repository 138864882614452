import calendar from '../../assets/calendar.svg'
import calendarcheck from '../../assets/calendarcheck.svg'
import { FaArrowLeft,FaArrowRight  } from "react-icons/fa6";

import dayjs,{Dayjs} from 'dayjs'
import { useState } from 'react'
import { generateDate, months } from "./util/calendar"
import cn from "./util/cn"

const Calendar = () => {
    const days = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
	const currentDate = dayjs();
	const [today, setToday] = useState<Dayjs>(currentDate);
	const [selectDate, setSelectDate] = useState<Dayjs>(currentDate)
    return (
        <>
            <div className="w-[326px] h-[592px] relative">
                <div className="w-10 h-9 px-0.5 left-[242.36px] top-[14px] absolute bg-neutral-200/80 rounded-lg justify-start items-start gap-2 inline-flex">
                    <div className="w-9 h-9 px-1.5 py-2 justify-center items-center flex">
                        <div className="grow shrink basis-0 self-stretch justify-center items-center inline-flex">
                            <div className="w-6 h-5 relative flex-col justify-start items-start flex">
                                <img src={calendar} alt='calendar'/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-[326px] h-[592px] left-0 top-0 absolute">
                    
                    <div className="w-[326px] h-[592px] left-0 top-0 absolute rounded-lg border border-zinc-400/50" />               
                    <div className="w-[109.38px] left-[48.61px] top-[20px] absolute text-center text-black text-base font-semibold font-['Lexend']">My Schedule</div>
                    
                    {/* ----------------------calendar main section--------------------- */}
                    <div className="w-[265.95px] h-[220px] px-3 py-1 left-[30.03px] top-[55px] absolute bg-white flex-col justify-start items-center inline-flex">
                        <div className="self-stretch pb-1 justify-between items-start inline-flex px-2">
                                                      
                            <FaArrowLeft className='text-black/40 cursor-pointer hover:scale-105 transition-all z-10' 
                                onClick={() => {
                                    setToday(today.month(today.month() - 1))
                                }}/>
                        
                            <div className="w-[70px] px-2 py-0.5 rounded justify-center items-center flex">
                                <div className="text-center text-black/40 text-xs font-extrabold font-['Inter']">{months[today.month()]}</div>
                            </div>

                            <FaArrowRight className='text-black/40 cursor-pointer hover:scale-105 transition-all' 
                                onClick={() => {
                                    setToday(today.month(today.month() + 1))
                                }}/>

                        </div>
                    
                        <div className="self-stretch grow shrink basis-0 flex-col justify-between items-center flex">
                            {/* -----------------Calendar Heading (Sun, Mon.....)----------------- */}
                            <div className="self-stretch justify-between items-center inline-flex">

                                {days.map((day, index) => (
                                    <div key={index} className={`w-6 h-6 rounded justify-center items-center flex ${day === 'Sa' || day === 'Su' ? 'text-red-700' : 'text-black'}`}>
                                        <div className="text-center text-[11px] font-normal font-['Inter']">{day}</div>
                                    </div>
                                ))}

                            </div>
                            <div className=" grid grid-cols-7 ">
                                {generateDate(today.month(), today.year()).map(
                                    ({ date, currentMonth, today }, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="p-2 text-center h-7 grid place-content-center text-sm "
                                            >
                                                <h1
                                                    className={cn(
                                                        currentMonth ? "" : "text-gray-400",
                                                        today
                                                            ? "bg-[#b5484855] border-[#a31515c1] border-[1px]"
                                                            : "",
                                                        selectDate
                                                            .toDate()
                                                            .toDateString() ===
                                                            date.toDate().toDateString()
                                                            ? "bg-[#b04a4a8b] border-[#a31515c1] border-[1px]"
                                                            : "",
                                                        "h-6 w-6 rounded-full grid place-content-center hover:bg-[#BE343433] hover:text-white transition-all cursor-pointer select-none"
                                                    )}
                                                    onClick={() => {
                                                        setSelectDate(date);
                                                    }}
                                                >
                                                    {date.date()}
                                                </h1>
                                            </div>
                                        );
                                    }
                                )}
                            </div> 
                        </div>
                    </div>


                    <div className="w-[25.74px] h-6 px-[5.63px] py-[7.25px] left-[252.01px] top-[83px] absolute justify-center items-center inline-flex" />
                    <div className="w-6 h-[25.74px] px-[7.25px] py-[5.63px] left-[47.18px] top-[83px] absolute origin-top-left -rotate-90 flex-col justify-center items-center inline-flex" />
                    <div className="w-[257.37px] h-[0px] left-[33.24px] top-[293px] absolute border border-zinc-400/50"></div>
                    <div className="w-[199.46px] left-[25.74px] top-[342px] absolute text-zinc-900 text-base font-light font-['Lexend'] leading-normal">Wednesday, 21 Feb 2023</div>
                    <div className="w-[157.64px] left-[24.66px] top-[461px] absolute text-zinc-900 text-base font-light font-['Lexend'] leading-normal">Friday, 23 Feb 2023</div>
                    <div className="w-[266px] h-[51px] left-[25.74px] top-[386px] absolute justify-start items-center gap-5 inline-flex">
                    <div className="text-zinc-900 text-lg font-semibold font-['Lexend'] leading-relaxed">09:30</div>
                    <div className="flex-col justify-start items-start gap-[5px] inline-flex">
                        <div className="text-zinc-900 text-sm font-light font-['Lexend'] leading-snug">UI/UX Designer</div>
                        <div className="text-zinc-900 text-base font-semibold font-['Lexend'] leading-normal">Practical Task Review</div>
                    </div>
                    </div>
                    <div className="w-[238px] h-[51px] left-[24.66px] top-[505px] absolute justify-start items-center gap-5 inline-flex">
                    <div className="text-zinc-900 text-lg font-semibold font-['Lexend'] leading-relaxed">13:30</div>
                    <div className="flex-col justify-start items-start gap-[5px] inline-flex">
                        <div className="text-zinc-900 text-sm font-light font-['Lexend'] leading-snug">Sales Manager</div>
                        <div className="text-zinc-900 text-base font-semibold font-['Lexend'] leading-normal">Final Sales Review</div>
                    </div>
                    </div>
                    <div className="w-[102.95px] h-[25px] left-[34.32px] top-[305px] absolute">
                    <div className="w-[102.95px] h-6 left-0 top-[1px] absolute rounded-lg border border-black/40" />
                    <div className="w-[90px] h-6 left-[13.35px] top-0 absolute justify-start items-center gap-2 inline-flex">
                        <div className="justify-start items-center gap-2 flex">
                            <img src={calendarcheck} alt='calendarcheck' className="w-4 h-4 flex-col justify-center items-center inline-flex" />
                            <div className="text-red-700 text-xs font-light font-['Lexend'] leading-normal">Add Event</div>
                        </div>
                    </div>
                    </div>
                    <div className="w-6 h-[18.04px] py-[1.50px] left-[291px] top-[223.07px] absolute justify-center items-center inline-flex" />
                    <div className="w-6 h-[18.04px] py-[1.50px] left-[291px] top-[312.53px] absolute justify-center items-center inline-flex" />
                </div>
            </div>
        </>
    )

}

export default Calendar