export const updateExpireTime = () => {
    const expireTime = Date.now() + 1000000; // Setting expiration time to 5 seconds from now

    localStorage.setItem("expireTime", JSON.stringify(expireTime)); // Store expiration time as string
}

export const checkForInactivity = () => {
    const expireTime: number | null = JSON.parse(localStorage.getItem("expireTime") || "null"); // Parse expiration time

    if (expireTime && expireTime < Date.now()) { // Ensure expiration time exists and it's less than current time
        localStorage.clear(); // Clear localStorage if expired
    }
}