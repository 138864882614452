import React from 'react';
import { AttendanceLogwithEmployee} from '../../../models/Attendance';

interface PrintTitleProps {
    employee: AttendanceLogwithEmployee;
    filterParams:{
        dateFrom: string;
        dateTo: string;
        month: string;
    }
}
// Mapping of Nepali month numbers to their names
const nepaliMonths: { [key: string]: string } = {
    '01': 'Baishakh',
    '02': 'Jestha',
    '03': 'Asar',
    '04': 'Shrawan',
    '05': 'Bhadra',
    '06': 'Ashwin',
    '07': 'Kartik',
    '08': 'Mangsir',
    '09': 'Poush',
    '10': 'Magh',
    '11': 'Falgun',
    '12': 'Chaitra'
  };

const PrintTitleId: React.FC<PrintTitleProps> = ({ employee,filterParams }) => {

        const numberOfDays = (dateFrom:any, dateTo:any) => {
            const fromDate:any = new Date(dateFrom);
            const toDate:any = new Date(dateTo);
            const timeDifference = toDate - fromDate; // Difference in milliseconds
            const daysDifference = timeDifference / (1000 * 60 * 60 * 24); // Convert to days
            // return daysDifference;
            return isNaN(daysDifference) ? 0 : daysDifference;
        };
        
        const weekends = (dateFrom: any, dateTo: any) => {
            const fromDate: any = new Date(dateFrom);
            const toDate: any = new Date(dateTo);
            let weekendsCount = 0;
            
            // Loop through each day between dateFrom and dateTo
            for (let d = new Date(fromDate); d <= toDate; d.setDate(d.getDate() + 1)) {
                if (d.getDay() === 6) { // Sunday (0) or Saturday (6)
                    weekendsCount++;
                }
            }
            
            return weekendsCount;
        };
        const presentDays = (attendanceRecords: AttendanceLogwithEmployee) => {
            // Create a set of unique dates with attendance records
            const attendanceDates = new Set(attendanceRecords.attendance.map(record => new Date(record.checkin_time).toDateString()))
            return attendanceDates.size-1;
        };
    
      const days = numberOfDays(filterParams?.dateFrom, filterParams?.dateTo);
      const wkends = weekends(filterParams?.dateFrom, filterParams?.dateTo);
      const presents = presentDays(employee);
      // Extract the fiscal year from the first employee's verify_nepali_date
      const nepali_date = employee.attendance[0]?.nepali_verify_date || employee.attendance[0]?.nepali_data || ''
      const fiscalYear = nepali_date ? nepali_date.substring(0, 4) : 'Unknown';
      const nepaliMonthNumber = nepali_date ? nepali_date.substring(5, 7) : 'Unknown';
      const nepaliMonth = nepaliMonths[nepaliMonthNumber] || 'Unknown';

    return (
        <div>
            <div className='text-center text-xl font-bold py-2 leading-none'>
                Monthly Ledger Report
            </div>
            <div className='text-center text-lg leading-none'>Fiscal Year: Fiscal Year {fiscalYear}</div>
            <div className='flex mt-1 items-end justify-between'>
                <div className='pl-4 items-end mb-1'>
                    <div>Month: {filterParams.month || nepaliMonth}</div>
                    <div>Staff Name: {employee.name}</div>
                </div>
                <div className='mb-2'>
                    <div className='text-center text-base font-bold '>Monthly Summary</div>
                    <div className='grid grid-cols-8 gap-2 px-2 py-1 border border-zinc-400/10' style={{ gridTemplateColumns: '1fr 1fr 1fr 0.7fr 1.2fr 0.7fr 1fr 1fr' }}>
                        <div className="text-sm font-light leading-normal min-w-[40px]">Total Days</div>
                        <div className="text-sm font-light leading-normal min-w-[40px]">Weekend</div>
                        <div className="text-sm font-light leading-normal min-w-[40px]">Holiday</div>
                        <div className="text-sm font-light leading-normal min-w-[40px]">Leave</div>
                        <div className="text-sm font-light leading-normal min-w-[40px]">Working Days</div>
                        <div className="text-sm font-light leading-normal min-w-[40px]">Absent</div>
                        <div className="text-sm font-light leading-normal min-w-[40px]">Present Days</div>
                        <div className="text-sm font-light leading-normal min-w-[40px]">Count Days</div>
                    </div>
                    <div className='grid grid-cols-8 gap-2 px-2 py-1 border-b border-r border-l border-zinc-400/10' style={{ gridTemplateColumns: '1fr 1fr 1fr 0.7fr 1.2fr 0.7fr 1fr 1fr' }}>
                        <div className="text-sm font-light leading-normal min-w-[40px]">{days}</div>
                        <div className="text-sm font-light leading-normal min-w-[40px]">{wkends}</div>
                        <div className="text-sm font-light leading-normal min-w-[40px]">0</div>
                        <div className="text-sm font-light leading-normal min-w-[40px]">N/A</div>
                        <div className="text-sm font-light leading-normal min-w-[40px]">{days-wkends}</div>
                        <div className="text-sm font-light leading-normal min-w-[40px]">{days - presents - wkends}</div>
                        <div className="text-sm font-light leading-normal min-w-[40px]">{presents}</div>
                        <div className="text-sm font-light leading-normal min-w-[40px]">{days - presents - wkends}</div>
                    </div>

                
                </div>
            </div>
        </div>
        
    );
};

export default PrintTitleId;