import { FaAngleDown } from "react-icons/fa6";
import "react-circular-progressbar/dist/styles.css"
import { CircularProgressbar } from "react-circular-progressbar"
const TaskOverview:React.FC =() => {
    const progress:number = 80
    return (
        <>
            <div className="w-[340px] h-[340px] px-6 py-6 rounded-lg border border-zinc-300 mt-3 sm:max-[1360px]:ml-3">
                <div className='flex justify-between'>
                    <div className="w-[154px] h-[22px]   justify-start items-start gap-2 inline-flex">
                        <div className="text-black text-base font-semibold font-['Lexend'] leading-6">Tasks Overview</div>
                    </div>
                    <div className="w-35 h-6 px-2 py-3 rounded-lg border border-neutral-400 justify-center items-center inline-flex gap-2">
                        <p className="text-right text-zinc-900 text-sm font-light font-['Lexend'] leading-snug">This Week</p>
                        <FaAngleDown className="w-4 h-4 px-[5px] justify-center items-center flex" />
                    </div>
                </div>
                <div className="w-full mt-4 h-[0px] border border-zinc-300"></div>
                
                <CircularProgressbar
                    value={progress}
                    text={`${progress}%`}
                    circleRatio={1}
                    styles={{
                        trail:{
                            stroke:"#B1EEC7",
                            transform: 'rotate(-126* deg)',
                            transformOrigin:"center center",
                        },
                        path:{
                            transform: 'rotate(-126 deg)',
                            transformOrigin:"center center",
                            stroke: "#64e855"
                        },
                        text:{
                            fill:"#000000",
                            fontFamily: "Lexend",
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "22px"
                        }

                    }}

                    strokeWidth={8}

                    className="h-[60%] mt-12"
                />
            </div>
        </>
    )
}

export default TaskOverview