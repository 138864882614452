import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import LoginForm from '../../Partials/LoginForm'
import { UserService } from '../../Services/User.service'

type UserProps = {
    userService : UserService
}

export const Welcome = ({userService}:UserProps) => {
    const [email, setEmail] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const navigate = useNavigate()
    const handleLogin = async (event:React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        try{
            const loggedUser = await userService.login({ email,password })
            if(loggedUser.data.access_token){
            localStorage.setItem('token',loggedUser.data.access_token)
            userService.setToken(localStorage.getItem('token'))
            const currentUser = await userService.getCurrentUser()
            console.log(currentUser)
            localStorage.setItem('currentUser',JSON.stringify(currentUser))

            const noti='Logged In Successfully'
            navigate('/',{state:{noti:noti}})
            } else{
                const noti = loggedUser.data.errors[0].title +' '+ loggedUser.data.errors[0].description
                navigate('/login', { state: { noti: noti, status: 'error' } })
            }

        } catch (exception) {
            throw (exception)
        }
    }
    
  return (
    <>
        <div className="flex h-[100vh] items-center px-2 justify-center">
                                                
            <div className="flex-col justify-start items-start gap-[30px] flex">
                <div className="flex-col justify-start items-start gap-[5px] flex">
                    <div className="text-zinc-900 text-3xl font-semibold font-lexend leading-10">Welcome  </div>
                    <div className="text-zinc-400 text-base font-light font-lexend leading-normal">Please login here</div>
                </div>
    

                <LoginForm handleLogin={handleLogin} 
                emailChangeEvent={(e)=>setEmail(e.target.value)} 
                passwordChangeEvent={(e)=>setPassword(e.target.value)}/>
            </div>

        </div>
    </>
  )
}