import list from '../../assets/list.svg'
import { FaPlus } from "react-icons/fa6";
const ToDoList:React.FC = () => {
    return (
        <>
            <div className="w-[340px] h-[340px] px-6 py-6 rounded-lg border border-zinc-300 sm:max-[1360px]:-mt-3 sm:max-[1360px]:ml-3">
                <div className='flex justify-between'>
                    <div className="w-[154px] h-[22px]   justify-start items-start gap-2 inline-flex">
                        <img src={list} alt='list' className="w-5 h-[22px] relative" />
                        <div className="text-black text-base font-semibold font-['Lexend'] leading-snug">To-do List</div>
                    </div>
                    <div className="w-35 h-6 px-2 py-3 rounded-lg border border-neutral-400 justify-center items-center inline-flex gap-2 text-secondRed">
                        <FaPlus className='h-5'/>
                        <p className="font-lexend font-normal text-14 leading-22">Add Task</p>
                    </div>
                </div>
                <div className="w-full mt-4 h-[0px] border border-zinc-300"></div>
                <div className='flex items-start gap-2'>
                    <div>
                        <input type='checkbox' id='todoinput1'/>
                    </div>
                    <div className="font-lexend font-normal text-sm leading-6">
                        Conduct an Employee Engagement Survey
                    </div>
                </div>
                <div className='flex items-start gap-2'>
                    <div>
                        <input type='checkbox' id='todoinput2'/>
                    </div>
                    <div className="font-lexend font-normal text-sm leading-6">
                        Verify the performance of the last week
                    </div>
                </div>
                <div className='flex items-start gap-2'>
                    <div>
                        <input type='checkbox' id='todoinput3'/>
                    </div>
                    <div className="font-lexend font-normal text-sm leading-6">
                        Meeting with the Client
                    </div>
                </div>
                <div className='flex items-start gap-2'>
                    <div>
                        <input type='checkbox' id='todoinput4'/>
                    </div>
                    <div className="font-lexend font-normal text-sm leading-6">
                        Verify the proposal of SEO department
                    </div>
                </div>
                
            </div>
        </>
    )
}
export default ToDoList