import PaginationSection, { PageData } from "../Components/PaginationSection"
import Dropdown from "../Components/Ui/Dropdown"
import { recent_logsType } from "../Pages/Dashboard/DashboardSection"

type EmployeeStatusProps = {
    page: number
    setPage: React.Dispatch<React.SetStateAction<number>>
    pageSize: number
    setPageSize: React.Dispatch<React.SetStateAction<number>>
    pageData: PageData
    recent_logs: recent_logsType[]
}
const EmployeeStatus = ({page, setPage,pageSize, setPageSize, pageData,recent_logs}:EmployeeStatusProps) => {
    console.log(recent_logs)
  return (
    <>
      <div className="flex-auto border rounded border-zinc-300 pb-6 mobile:w-[360px] [ overflow-x-scroll sm:w-full sm:overflow-auto">
          <div className="flex justify-between pt-6 px-12">
              <div className="flex text-[25px] font-medium font-kantipur leading-snug">
                  {"sd{rf/L l:ylt"}
              </div>
              <Dropdown>{";a} x]g{'xf];"}</Dropdown>
          </div>
        <div className="w-full overflow-y-scroll">
            {/* ------------------------------------table Heading------------------------------ */}
            <div className='grid grid-cols-4 gap-2 px-4 py-4 border mt-3 border-zinc-300/50 place-items-center' style={{ gridTemplateColumns: '1.8fr 1fr 1.2fr 1fr' }}>
                <div className="text-zinc-500 text-[18px] font-semibold font-kantipur leading-normal w-40">{'gfd'}</div>
                <div className="text-zinc-500 text-[18px] font-semibold font-kantipur leading-normal w-40">{'Od]n'}</div>
                <div className="text-zinc-500 text-[18px] font-semibold font-kantipur leading-normal w-40">{'e"ldsf'}</div>
                <div className="text-zinc-500 text-[18px] font-semibold font-kantipur leading-normal">{'l:ylt'}</div>
            </div>
            {/* ---------------------table content--------------------------------- */}
            {recent_logs.map((recent_log,index) => (
            <div key = {index} className='grid grid-cols-4 gap-2 px-4 py-4 place-items-center' style={{ gridTemplateColumns: '1.8fr 1fr 1.2fr 1fr' }}>
                <div className="text-black text-xs font-semibold font-lexend leading-normal w-40">{recent_log.employee.name}</div>
                <div className="text-black text-xs font-normal font-lexend leading-normal w-40">{recent_log.employee.email}</div>
                <div className="text-blue-800 text-xs font-medium font-lexend leading-normal w-40">{recent_log.employee.position}</div>
                <div className="w-[77px] h-[41.78px] px-4 py-1 bg-green-200 rounded-xl border border-lime-600 justify-start items-center gap-10 inline-flex">
                    <div className="text-lime-600 text-xs font-semibold font-lexend leading-normal">{recent_log.status}</div>
                </div>
            </div>))}
            {/* <div className='grid grid-cols-4 gap-2 px-4 py-4 place-items-center' style={{ gridTemplateColumns: '1.8fr 1fr 1.2fr 1fr' }}>
                <div className="text-black text-xs font-semibold font-lexend leading-normal w-40">Phil Collins</div>
                <div className="text-black text-xs font-normal font-lexend leading-normal w-40">philcollins002@gmail.com</div>
                <div className="text-blue-800 text-xs font-medium font-lexend leading-normal w-40">Sales Manager</div>
                <div className="w-[77px] h-[41.78px] px-4 py-1 bg-amber-100/70 rounded-xl border border-orange-400/70 justify-start items-center gap-10 inline-flex">
                <div className="text-orange-400/70 text-[10px] font-semibold font-lexend leading-normal">Withdraw</div>
                </div>
            </div>
            <div className='grid grid-cols-4 gap-2 px-4 py-4 place-items-center' style={{ gridTemplateColumns: '1.8fr 1fr 1.2fr 1fr' }}>
                <div className="text-black text-xs font-semibold font-lexend leading-normal w-40">Jake Nicholson</div>
                <div className="text-black text-xs font-normal font-lexend leading-normal w-40">jakeamerica052@gmail.com</div>
                <div className="text-blue-800 text-xs font-medium font-lexend leading-normal w-40">Senior Developer</div>
                <div className="w-[77px] h-[41.78px] px-4 py-1 bg-green-200 rounded-xl border border-lime-600 justify-start items-center gap-10 inline-flex">
                    <div className="text-lime-600 text-xs font-semibold font-lexend leading-normal">Present</div>
                </div>
            </div>
            <div className='grid grid-cols-4 gap-2 px-4 py-4 place-items-center' style={{ gridTemplateColumns: '1.8fr 1fr 1.2fr 1fr' }}>
                <div className="text-black text-xs font-semibold font-lexend leading-normal w-40">Esther Howard</div>
                <div className="text-black text-xs font-normal font-lexend leading-normal w-40">estherhoward@gmail.com</div>
                <div className="text-blue-800 text-xs font-medium font-lexend leading-normal w-40">Junior EmployeeStatuser</div>
                <div className="w-[77px] h-[41.78px] px-4 py-1 bg-red-300 rounded-xl border border-red-700 justify-start items-center gap-10 inline-flex">
                    <div className="text-red-700 text-xs font-semibold font-lexend leading-normal">Absent</div>
                </div>
            </div> */}
        </div>
        <div className="">
        <PaginationSection
                page = {page}
                setPage = {setPage}
                pageSize={pageSize}
                setPageSize = {setPageSize}
                pageData = {pageData}/>
        </div>
        
      </div>
    </>
  )
}

export default EmployeeStatus
