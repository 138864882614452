import React, { useEffect, useRef, useState } from 'react'
import {AttendanceLogwithEmployee } from '../../../models/Attendance';
import { AttendanceService } from '../../../Services/Attendance.service';
import { useParams } from 'react-router-dom';
import PaginationSection, { PageData } from '../../../Components/PaginationSection';
import { useReactToPrint } from 'react-to-print';
import { MdOutlineLocalPrintshop } from 'react-icons/md';
import CustomPrint from './CustomPrint';
import { EmployeeData } from '../../../models/Employee';
import MonthlyAttendance from '../../../Partials/MonthlyAttendance';

type AttendanceDetailProps = {
    attendanceService : AttendanceService
    filterParams:{
        dateFrom: string;
        dateTo: string;
        month: string;
    }
}

const AttendanceDetail = ({attendanceService,filterParams}:AttendanceDetailProps) => {
//   const dateIntervalRef = useRef({dateFrom:'',dateTo:''})
  const token = localStorage.getItem('token')
  const {employeeId} = useParams()
  const [attendanceLog,setAttendanceLog] = useState<AttendanceLogwithEmployee>({
      id: 1,
      image: "",
      position: "",
      department:"",
      email:"",
      phone:1,
      privilege:"",
      card_number:1,
      password:'',
      is_enabled:"",
      name:"",
      attendance:[
        // {
        // employee_id: 1,
        // checkin_time: '',
        // checkout_time: '',
        // work_hours: '',
        // verify_date: '',
        // nepali_verify_date: '',
        // nepali_data: '',
        // status: '',
        // remarks:''
        // }
      ]
      })
    

  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  // eslint-disable-next-line
  const [pageData, setPageData] = useState<PageData>({ 
  page_size: 32,
  page_count: 1,
  total_items: 32,
  page_number: 1})


//   const formatDate = (dateString:string) => {
//       const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
//       return new Date(dateString).toLocaleDateString(undefined, options);
//   }
  const formatDayOfWeek = (dateString:string) => {
      const options: Intl.DateTimeFormatOptions = { weekday: 'long' };
      return new Date(dateString).toLocaleDateString(undefined, options);
  }
  const formatTime = (timeString:string) => {
        if (!timeString) {
            return '---';
        }
      return new Date(timeString).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  }
  const calculateWorkingHours = (checkin:string, checkout:string) => {
      const checkinTime = new Date(checkin);
      const checkoutTime = new Date(checkout);
  
      // Calculate the difference in milliseconds
      const diffMs = checkoutTime.getTime() - checkinTime.getTime();
  
      // Convert milliseconds to hours and minutes
      const hours = Math.floor(diffMs / (1000 * 60 * 60));
      const minutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
  
      // Format the result
      if (!checkout || !checkin) {
            return '---';
        }
      return `${hours}:${minutes.toString().padStart(2, '0')} hrs`
  }
  useEffect(() => {
      const fetchData = async () => {

          try{
              attendanceService.setToken(token)
              const res = await attendanceService.getAttendanceFromId({
                id:employeeId,
                from_date:filterParams.dateFrom,
                to_date:filterParams.dateTo
                })
                console.log(res.employee.attendance)
                setAttendanceLog(res.employee)
          } catch (exception) {
              console.log(exception)
          }
      }
      fetchData()
  },[token, employeeId, page, pageSize,filterParams,attendanceService,pageData])

  const [isPrinting,setIsPrinting] = useState<boolean>(false)
    const componentRef = useRef<HTMLDivElement>(null)
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onBeforeGetContent: ()=> setIsPrinting(true),
        onAfterPrint: ()=> setIsPrinting(false),
    })
    
    const proceedWithPrinting = () => {
        setIsPrinting(true);
        setTimeout(()=>handlePrint(),1)
    };

  return (
      <>
          <div className='w-full overflow-x-scroll'>
            <button className="w-[111px] h-[50px] p-5 rounded-[10px] border border-zinc-400/20 justify-center items-center gap-2.5 inline-flex"
                onClick={proceedWithPrinting}>
                <div className='flex items-center'>
                    <MdOutlineLocalPrintshop  className='h-6 w-6'/>
                </div>
                <div className="text-zinc-900 text-[20px] font-light font-kantipur leading-normal"

                >{'lk|G6'}</div>
            </button>
            <MonthlyAttendance attendanceLog = {attendanceLog}/>
            <div className='min-w-full w-full' style={{ display: isPrinting ? 'block' : 'none' }} ref={componentRef}>
                {isPrinting && <CustomPrint employee={attendanceLog} filterParams={filterParams}/>}
              <div className='grid grid-cols-7 gap-2.5 px-2 py-1 border-b border-zinc-400/10 items-center justify-start' style={{ gridTemplateColumns: '1fr 1.2fr 1fr 1.2fr 1fr 1fr 3fr' }}>
                  <div className="text-zinc-400 text-[20px] font-light font-kantipur leading-normal min-w-[120px]">{'ldlt'}</div>
                  <div className="text-zinc-400 text-[20px] font-light font-kantipur leading-normal min-w-[100px]">{'lbg'}</div>
                  <div className="text-zinc-400 text-[20px] font-light font-kantipur leading-normal min-w-[100px]">{'r]s Og 6fOd'}</div>
                  <div className="text-zinc-400 text-[20px] font-light font-kantipur leading-normal min-w-[100px]">{'r]s cfpt 6fOd'}</div>
                  <div className="text-zinc-400 text-[20px] font-light font-kantipur leading-normal min-w-[100px]">{'sfd ug]{ ;do'}</div>
                  <div className="text-zinc-400 text-[20px] font-light font-kantipur leading-normal min-w-[100px]">{'l:ylt'}</div>
                  <div className="text-zinc-400 text-[20px] font-light font-kantipur leading-normal min-w-[100px]">{'s}lkmot'}</div>
              </div>
                {/* ---------------------table content--------------------------------- */}
                {/* Dynamically render attendance log */}
                {attendanceLog.attendance?.map((log, index) => (
                    <div key={index} className='grid grid-cols-7 gap-2.5 px-4 py-0 border-b border-zinc-400/10 items-center justify-start' style={{ gridTemplateColumns: '1fr 1.2fr 1fr 1.2fr 1fr 1fr 3fr' }}>
                        <div className="text-zinc-900 text-base font-light font-lexend leading-none min-w-[120px]">{log.nepali_verify_date || log.nepali_data}</div>
                        <div className="text-zinc-900 text-base font-light font-lexend leading-none min-w-[100px]">{formatDayOfWeek(log.verify_date)}</div>
                        <div className="text-zinc-900 text-base font-light font-lexend leading-none min-w-[100px]">{log.status === 'present' ? formatTime(log.checkin_time) : '----'}</div>
                        <div className="text-zinc-900 text-base font-light font-lexend leading-none min-w-[100px]">{log.status === 'present' ? formatTime(log.checkout_time) : '----'}</div>
                        <div className="text-zinc-900 text-base font-light font-lexend leading-none min-w-[100px]">{log.status === 'present' ? calculateWorkingHours(log.checkin_time, log.checkout_time) : '----'}</div>
                        <div className="pr-2.5 py-0.5 justify-start items-start gap-2.5 inline-flex min-w-[100px]">
                            <div className={`${log.status === 'present'?'bg-emerald-400/10': 'bg-red-300/10'} px-2 py-[3px] rounded justify-center items-center gap-2.5 flex`}>
                                <div className={`${log.status === 'present'?'text-emerald-400': 'text-red-300'} text-xs font-light font-['Lexend'] leading-[18px]`}>{log.status}</div>
                            </div>
                        </div>
                        <div className="text-zinc-900 text-base font-light font-lexend leading-none min-w-[200px]">{log.remarks}</div>
                    </div>
                ))}
            </div>
            {/* -----------------------------------Pagination Section--------------------------------- */}
            {/* <PaginationSection
                page = {page}
                setPage = {setPage}
                pageSize={pageSize}
                setPageSize = {setPageSize}
                pageData = {pageData}/> */}
          
      </div>
      </>
  )
}

export default AttendanceDetail
