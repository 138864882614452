import {Link} from 'react-router-dom'
import { FaCirclePlus } from "react-icons/fa6"
import { FaAngleLeft, FaAngleRight } from "react-icons/fa"
const AccessControl = () => {
    const visibleNumbers=[1,2,3,4]
    return (
        <>
            <div className="w-full rounded-[10px] border border-gray-200 mx-4">
                <div className="px-5">
                    <div className="flex justify-end mt-5 mb-[26px] px-[50px]">
                        <div className="flex-initial h-[50px] p-5 pr-10 bg-red-700 rounded-[10px] justify-center items-center gap-2.5 inline-flex">
                            <FaCirclePlus className="w-6 h-6 p-0.5 justify-center items-center flex text-red-700 rounded-full bg-white" />
                            <div className="text-white text-[22px] font-light font-kantipur leading-normal">{"yKg'xf]:f"}</div>
                        </div>
                    </div>
                    <div>
                        <div className='pt-[15px] pb-[25px] grid grid-cols-7 border-b border-zinc-400/10' style={{ gridTemplateColumns: '1fr 1fr 1fr' }}>
                            <div className="text-center text-zinc-400 text-base font-light font-lexend leading-normal">Permission Set Names</div>
                            <div className="text-center text-zinc-400 text-base font-light font-lexend leading-normal">Permission Description</div>
                            <div className="text-center text-zinc-400 text-base font-light font-lexend leading-normal">Actions</div>
                        </div>
                        <div className='pt-[10px] pb-[30px] grid grid-cols-7 border-b border-zinc-400/10' style={{ gridTemplateColumns: '1fr 1fr 1fr' }}>
                            <div className="text-center text-zinc-900 text-base font-light font-lexend leading-normal">Admission Permission Set</div>
                            <div className="text-center text-zinc-900 text-base font-light font-lexend leading-normal">All Admin Permissions</div>
                            <div className="flex justify-center">
                                <Link to='/settings/access-control/edit-permission'>
                                    <div className="w-[85px] text-center text-blue-800 text-base font-light font-['Lexend'] leading-normal">Edit</div>
                                </Link>                                
                                <div className="w-[104px] text-center text-red-700 text-base font-light font-['Lexend'] leading-normal">Delete</div>                
                            </div>
                        </div>
                        <div className='pt-[10px] pb-[30px] grid grid-cols-7 border-b border-zinc-400/10' style={{ gridTemplateColumns: '1fr 1fr 1fr' }}>
                            <div className="text-center text-zinc-900 text-base font-light font-lexend leading-normal">Developer Permission Set</div>
                            <div className="text-center text-zinc-900 text-base font-light font-lexend leading-normal">All Developer Permissions</div>
                            <div className="flex justify-center">                                
                                <div className="w-[85px] text-center text-blue-800 text-base font-light font-['Lexend'] leading-normal">Edit</div>
                                <div className="w-[104px] text-center text-red-700 text-base font-light font-['Lexend'] leading-normal">Delete</div>                
                            </div>
                        </div>
                        <div className='pt-[10px] pb-[30px] grid grid-cols-7 border-b border-zinc-400/10' style={{ gridTemplateColumns: '1fr 1fr 1fr' }}>
                            <div className="text-center text-zinc-900 text-base font-light font-lexend leading-normal">Sales Permission Set</div>
                            <div className="text-center text-zinc-900 text-base font-light font-lexend leading-normal">All Sales Permissions</div>
                            <div className="flex justify-center">                                
                                <div className="w-[85px] text-center text-blue-800 text-base font-light font-['Lexend'] leading-normal">Edit</div>
                                <div className="w-[104px] text-center text-red-700 text-base font-light font-['Lexend'] leading-normal">Delete</div>                
                            </div>
                        </div>
                        <div className='pt-[10px] pb-[30px] grid grid-cols-7 border-b border-zinc-400/10' style={{ gridTemplateColumns: '1fr 1fr 1fr' }}>
                            <div className="text-center text-zinc-900 text-base font-light font-lexend leading-normal">HR Permission Set</div>
                            <div className="text-center text-zinc-900 text-base font-light font-lexend leading-normal">All HR Permissions</div>
                            <div className="flex justify-center">                                
                                <div className="w-[85px] text-center text-blue-800 text-base font-light font-['Lexend'] leading-normal">Edit</div>
                                <div className="w-[104px] text-center text-red-700 text-base font-light font-['Lexend'] leading-normal">Delete</div>                
                            </div>
                        </div>

                    </div>

                    {/* ----------------------------------------Pagination Section --------------------------------- */}
                    <div className='flex justify-between w-full px-4 pr-8 py-6 flex-wrap items-center'>
                    <div className='inline-flex items-center gap-5'>
                        <div className="text-zinc-400 text-sm font-light font-['Lexend'] leading-snug">Showing</div>
                        <div className='inline-flex items-center px-3 py-3 gap-4 rounded-[10px] border border-zinc-400/20'>
                            <select id="pageSize" className='outline-none'>
                                <option value='10'>10</option>
                                <option value='8'>8</option>
                                <option value='6'>6</option>
                                <option value='3'>3</option>
                            </select>
                        </div>
                    </div>
                    <div className="text-zinc-400 text-sm font-light font-['Lexend'] leading-snug">Showing 1 to 4 out of 10 records</div>
                        <div className='inline-flex items-center'>
                            <FaAngleLeft/>
                            <div className='inline-flex px-2 gap-2'>
                                {visibleNumbers.map((number) => (
                                    <div key={number} 
                                        className={`text-center px-1.5 py-1 gap-2 font-lexend text-base font-light leading-normal ${2 === number ? 'border border-red-700 rounded-lg' : 'text-zinc-900'}`}
                                        >
                                        {number}
                                    </div>
                                ))}
                            </div>
                            <FaAngleRight/>
                        </div>
                    </div>
                </div>
                
            </div>      
        </>
    )
}
export default AccessControl