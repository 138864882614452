import React, { useContext, useState } from 'react'
import { LuSearch } from "react-icons/lu";
import { FaRegBell } from "react-icons/fa";
import { FaAngleDown } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { TitleState } from '../Pages/Home';



const Titlebar:React.FC = () => {

    const {titleState} = useContext(TitleState)

    const [drop,setDrop] = useState<Boolean>(false)
    const navigate = useNavigate()

    const handleLogout = async (event:React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault()
        
        try{
            localStorage.clear()
            navigate('/login',{state:{noti:'logout successfully!!'}})

        } catch (exception) {
            console.log(exception)
        }
    }
    const currentUserString = localStorage.getItem('currentUser');
    const currentUser = currentUserString ? JSON.parse(currentUserString) : null;
    
  return (
    <div className='relative flex flex-row justify-between items-center flex-wrap mt-4 mb-8 pr-8'>
        <div className="ml-4 h-[52px] flex-none">
            <p className='font-kantipur font-semibold text-[25px] leading-8'>
            {titleState.title==='8\of;af]8{' && <span className='text-secondRed'>{titleState.title}</span>}
            {titleState.title!=='8\of;af]8{' &&<span>{titleState.title}</span>}</p>
            <p className='font-kantipur font-light text-[20px] leading-4 text-customGray'>{titleState.subhead}</p>
        </div>
        <div className='flex flex-row space-x-4 flex-wrap-reverse max-lg:justify-between max-sm:gap-2'>
            <div className="w-[246px] h-[50px] px-4 py-[13px] rounded-[10px] border border-zinc-400/10 flex-col justify-center items-start gap-2.5 inline-flex">
                <div className="self-stretch justify-start items-center gap-2.5 inline-flex">
                    <div className='justify-center items-center flex"'><LuSearch className="h-6 w-6 text-black/50" /></div>
                    
                    <input id="searchInput" onClick={(e)=>{e.preventDefault();console.log('e.target.value')}} className="grow shrink basis-0 text-zinc-900 text-base font-light font-lexend leading-normal placeholder:font-kantipur placeholder:text-[20px] focus:outline-none" placeholder="vf]hL ug{'xf];"/>
                </div>
            </div>
            <div className="w-[47px] h-[50px] p-5 bg-zinc-400/10 rounded-[10px] justify-center items-center gap-2.5 inline-flex">
                <div>
                    <FaRegBell className='w-6 h-6'/>
                </div>
            </div>
            <div className="w-[173.83px] h-[50px] relative max-sm:flex">
                {/* <img className="w-[37.79px] h-10 left-[4.72px] top-[5px] absolute rounded-lg" alt='profile' src="https://via.placeholder.com/38x40" /> */}
                <div className="w-[173.83px] h-[50px] left-0 top-0 absolute rounded-lg border border-zinc-400/20" />
                    <div className="w-[82px] h-[42px] py-[9px] left-[47.24px] top-[5px] absolute flex-col justify-start items-start inline-flex">
                        <div className="text-zinc-900 text-base font-semibold font-['Lexend'] leading-normal">{currentUser.data.first_name}</div>
                    </div>
                    <div className='px-[5.5px] left-[140px] top-[15px] absolute justify-center items-center inline-flex cursor-pointer'>
                        <FaAngleDown className="w-[18px] h-5"
                            onClick={()=> setDrop(!drop)}/>
                    </div>
                {drop && 
                <div className='absolute top-[50px] bg-white z-10 w-full flex-col items-end px-4 shadow-md rounded-lg py-2'>
                <div className='text-zinc-900 text-sm font-normal font-lexend leading-normal cursor-pointer hover:bg-[#f3eeeee7]'
                onClick={() => {
                    localStorage.setItem('titlebar', JSON.stringify(
                        {
                            title: ';]l6ªx¿',
                            subhead: ';]l6ªsf hfgsf/L'
                        }))
                        setDrop(!drop)
                        navigate('/settings/change-password')

                }}> Change Password</div>
                <div className='text-zinc-900 text-sm font-normal font-lexend leading-normal cursor-pointer hover:bg-[#f3eeeee7]'
                        onClick={handleLogout}> Logout</div>
                </div>
                }
            </div>
        </div>
    </div>
  )
}

export default Titlebar
