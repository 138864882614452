type SetNotificationSliderProps = {
    notificationType: string
    recieveType:string
    id:string
    value: string
    changeEvent: React.ChangeEventHandler<HTMLInputElement>
}
const SetNotificationSlider = ({notificationType,recieveType,id,value, changeEvent}:SetNotificationSliderProps) => {
  return (
    <>
        <div className="rounded-lg border border-gray-200 justify-between items-center flex-1 flex">
            <div className="py-3 px-6 space-y-2 flex-col">
                <div className="text-black text-[19px] font-normal font-kantipur leading-snug">{notificationType}</div>
                <div className="text-zinc-500/80 text-[19px] font-light font-kantipur leading-snug">{recieveType}</div>
            </div>
            <div className="flex mx-6">
                <label htmlFor={id} className="flex relative bg-[#34C759] w-[50px] h-[30px] rounded-full overflow-hidden">
                    <input type="checkbox" id={id} className="sr-only peer" value={value} onChange={changeEvent}/>
                    <span className="flex absolute left-[-60px] top-[-5px] w-[60px] h-[35px] bg-dark duration-100 peer-checked:translate-x-[50px] "></span>
                    <span className="flex absolute left-[2px] top-[2px] w-[26px] h-[26px] bg-white rounded-full duration-150 peer-checked:translate-x-[21px]"></span>
                </label>
            </div>
        </div>
    </>
  )
}

export default SetNotificationSlider
