import { LoginCredentials } from '../models/Login'
import { HttpAdapter } from '../adapters/HttpClient'

export class UserService {
    private readonly http : HttpAdapter
    private token: string | null = null

    constructor(httpAdapter:HttpAdapter) {
        this.http = httpAdapter
    }

    setToken(token: string | null) {
        this.token = token;
    }

    async login(credentials:LoginCredentials):Promise<any> {
        try {
            const res= await this.http.post<any>('/account/login', credentials, undefined);
            return res
        } catch (error) {
            throw error; // Rethrow error
        }
    }

    async createUser(formdata:FormData):Promise<any> {
        try {
            const config = {
                headers: {
                    Authorization: this.token ? `Bearer ${this.token}` : '',
                    'Content-Type': 'multipart/form-data'
                }
            };
            const res= await this.http.post<any>(`/account/register`, formdata, config);
            return res
        } catch (error:any) {
            return error.response
        }
    }
    async getUsers({name,page,pageSize}:{name:string,page:number,pageSize:number}):Promise<any> {
        try {
            const config = {
                headers: {
                    Authorization: this.token ? `Bearer ${this.token}` : '',
                    'Content-Type': 'application/json'
                }
            };
            const res= await this.http.get<any>(`/account/alluser?name=${name}&page=${page}&page_size=${pageSize}`, config);
            return res
        } catch (error) {
            throw error; // Rethrow error
        }
    }
    async getCurrentUser():Promise<any> {
        try {
            const config = {
                headers: {
                    Authorization: this.token ? `Bearer ${this.token}` : '',
                    'Content-Type': 'application/json'
                }
            };
            const res= await this.http.get<any>(`/account/profile`, config);
            return res
        } catch (error) {
            throw error; // Rethrow error
        }
    }
    async changePassword({password,repassword}:{password:string,repassword:string}):Promise<any> {
        try {
            const content = {
                password:password,
                confirm_password:repassword
            }

            const config = {
                headers: {
                    Authorization: this.token ? `Bearer ${this.token}` : '',
                    'Content-Type': 'application/json'
                }
            };
            
            const res= await this.http.post<any>(`/account/password-change`,content, config);
            
            return res
        } catch (error) {
            throw error; // Rethrow error
        }
    }
    async resetPasswordLink({email}:{email:string}):Promise<any> {
        try {
            const content = {
                email:email,
            }

            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            
            const res= await this.http.post<any>(`/account/send-reset-password-email`,content, config);
            
            return res
        } catch (error) {
            throw error; // Rethrow error
        }
    }
    async resetUserPassword({password,confirmPassword,uid,token}:{
        password:string,confirmPassword:string,uid:string | undefined,token:string | undefined}):Promise<any> {
        try {
            const content = {
                password:password,
                confirm_password:confirmPassword
            }

            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            
            const res= await this.http.post<any>(`/account/reset-password/${uid}/${token}/`,content, config);
            
            return res

        } catch (error) {
            throw error; // Rethrow error
        }
    }
}