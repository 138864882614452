import React, { useEffect, useState } from 'react'
import logo from '../../assets/logo.png'
import { Route, Routes, useLocation } from 'react-router-dom'
import Forgotpage from './Forgotpage'
import Otppage from './Otp'
import NotificationBar, { Noti } from '../../Components/Ui/NotificationBar'

import Welcome from '.'
import Register from './Register'
import PasswordReset from './PasswordReset'


const Login = () => {
    const location = useLocation()
    const [noti,setNoti] = useState<Noti>({msg:'',status:''})

    useEffect(()=>{
        if (location.state?.noti) {
            setNoti({ msg: location.state.noti, status: location.state.status || 'success' })
            setTimeout(() => {
                setNoti({ msg: '', status: '' })
            }, 2000)
        }
    },[location.state])

  return (
    <>
        <div className="flex flex-col bg-white h-screen sm:flex-row">
            <NotificationBar noti={noti}/>
            <div className="flex-1 bg-custom-gradient">
                <div className='p-2 space-y-[30px] sm:mt-[30vh]'>                       
                
                    <div className='flex justify-center'>
                        <img src={logo} alt='logo' className='h-[73px] w-[254px] min-w-[254px]'/>
                    </div>
                    <div className='justify-center hidden sm:flex'>
                        <span className="text-white text-base font-normal font-lexend leading-normal">Empowering Management, Elevating Workplace</span>
                    </div>
                </div>
            </div>

            <div className="flex-1 bg-dark h-screen">
                <Routes>                           
                    <Route path="/" element={<Welcome/>}/>
                    <Route path="forgotpage" element={<Forgotpage/>}/>
                    <Route path="otppage" element={<Otppage/>}/>                            
                    <Route path="register" element={<Register/>}/>                            
                    <Route path="password-reset/:uid/:token" element={<PasswordReset/>}/>                            
                </Routes>                        
            </div>
        </div>
    </>
  )
}

export default Login
