import React from 'react';
import Design from './Design';

const Design0: React.FC = () => {
  
    return (
      <div>
        <Design ></Design>
      </div>
    );
  }

export default Design0;
