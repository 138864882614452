type RedCardProps = {
    component : React.ComponentType<{className:string}>
    text : string
    num : number
  }
  
  const RedCard = ({ component:Component, text, num }:RedCardProps) => {
    return (
      <>
        <div className={`h-[120px] w-[195px] flex flex-col bg-customRed rounded-lg shadow-lg hover:scale-105 duration-500`}>
            <div className='flex flex-row space-x-3 px-4 mt-3 items-center'>
                <div><Component className={'h-[24px] w-[24px] my-[8px] text-red-700'}/></div>
                <div><p className="w-[124px] text-red-700 text-lg font-semibold font-kantipur leading-10">{text}</p></div>
            </div>
            <div>
                <p className="text-black text-2xl font-semibold font-['Lexend'] leading-[38px] px-4">{num}</p>
            </div>
        </div>
      </>
    )
  }
  
  export default RedCard