import axios, { AxiosRequestConfig } from 'axios'

export class HttpAdapter {
    private readonly baseUrl

    constructor ({ baseUrl } : { baseUrl : string}) {
        this.baseUrl = baseUrl
    }

    async get<T>(url: string, config: AxiosRequestConfig | undefined):Promise<T> {
        try{
            const res = await axios.get(this.baseUrl + url, config)
            return res.data
        } catch (error:any) {
            if (error.response) {
                return error.response
            } else 
            throw new Error(error.message)
        }
    }

    async post<T>(url: string , data: any, config: AxiosRequestConfig | undefined):Promise<T> {
        try{
            const res = await axios.post(this.baseUrl + url, data, config)
            return res.data
        } catch (error:any) {
            if (error.response) {
                return error.response
            } else 
            throw new Error(error.message)
        }
    }

    async put<T>(url: string , data: any, config: AxiosRequestConfig | undefined):Promise<T> {
        try{
            const res = await axios.put(this.baseUrl + url, data, config)
            return res.data
        } catch (error:any) {
            if (error.response) {
                return error.response
            } else 
            throw new Error(error.message)
        }
    }

    async delete<T>(url: string , config: AxiosRequestConfig | undefined):Promise<T> {
        try{
            const res = await axios.delete(this.baseUrl + url, config)
            return res.data
        } catch (error:any) {
            if (error.response) {
                return error.response
            } else 
            throw new Error(error.message)
        }
    }

}