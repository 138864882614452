const EditPermission = () => {
    return (
        <>
            <div className="w-full rounded-[10px] border border-gray-200 mx-4 px-9 pt-[36px]">
                <div className="flex gap-[68px] mb-[34px] overflow-hidden">
                    <div className="text-red-700 text-[22px] font-normal font-kantipur leading-snug mt-2">
                        {'e"ldsf ;]6 gfd'}
                    </div>
                    <div className="flex flex-initial w-[500px] h-10 rounded-[10px] border border-zinc-600/30 items-center px-2.5">
                        <input id="roleName" className="outline-none h-full w-full"/>
                    </div>
                </div>
                <div className="flex gap-[68px] mb-[34px] overflow-hidden">
                    <div className="text-red-700 text-[22px] font-normal font-kantipur leading-snug mt-2">
                        {'e"ldsf ljj/0f'}
                    </div>
                    <div className="flex flex-initial w-[500px] h-[100px] rounded-[10px] border border-zinc-600/30 items-start px-2.5">
                        <textarea id="roleName" className="outline-none h-full w-full"/>
                    </div>
                </div>

                {/* ----------------------------Permission tokens----------------------------------------------- */}
                <div className="flex flex-col justify-center border-t-[1.5px] mb-[18px] border-zinc-500/40 pt-6">
                    <div className="flex justify-between w-full mb-[18px]">
                        <div>
                            <div className="text-red-700 text-[28px] font-normal font-kantipur leading-snug">{'cg\'dlt 6f]sg'}</div>
                        </div>
                        <div className="px-[40px] py-[12px] rounded-[10px] border border-zinc-400/50 justify-center items-center inline-flex">
                            <div className="text-zinc-900 text-base font-light font-lexend leading-normal">Save</div>
                        </div>
                    </div>

                    <div className="flex flex-wrap justify-between w-full gap-[120px]">
                        <div className="flex flex-col flex-initial w-[390px]">
                            <div className="grid grid-cols-3 justify-start items-center h-14 bg-zinc-100 border-t border-b border-zinc-300 w-full" style={{ gridTemplateColumns: '1.4fr 1fr 1fr' }}>
                                <div className="px-4 text-center text-black text-sm font-medium font-roboto leading-tight tracking-tight">Page Name</div>
                                <div className="px-4 text-center text-black text-sm font-medium font-roboto leading-tight tracking-tight">Roles</div>
                                <input type="checkbox"  className="appearance-none w-[18px] h-[18px] border-[3px] border-black/60 rounded-sm"/>
                            </div>
                            <div className="w-full border-b border-black/60">
                                <div className="grid grid-cols-3 justify-start items-center h-14 border-b border-zinc-300 w-full" style={{ gridTemplateColumns: '1.4fr 1fr 1fr' }}>
                                    <div></div>
                                    <div className="px-4 text-center text-black text-sm font-normal font-roboto leading-tight tracking-tight">View</div>
                                    <input type="checkbox"  className="appearance-none checked:appearance-auto checked:accent-white w-[18px] h-[18px] border-[3px] border-black/60 rounded-sm"/>
                                </div>
                                <div className="grid grid-cols-3 justify-start items-center h-14 border-b border-zinc-300 w-full" style={{ gridTemplateColumns: '1.4fr 1fr 1fr' }}>
                                    <div className="px-4 text-center text-black text-sm font-normal font-roboto leading-tight tracking-tight">All Employees</div>
                                    <div className="px-4 text-center text-black text-sm font-normal font-roboto leading-tight tracking-tight">Add/Edit</div>
                                    <input type="checkbox"  className="appearance-none checked:appearance-auto checked:accent-white w-[18px] h-[18px] border-[3px] border-black/60 rounded-sm"/>
                                </div>
                                <div className="grid grid-cols-3 justify-start items-center h-14 w-full" style={{ gridTemplateColumns: '1.4fr 1fr 1fr' }}>
                                    <div></div>
                                    <div className="px-4 text-center text-black text-sm font-normal font-roboto leading-tight tracking-tight">Delete</div>
                                    <input type="checkbox"  className="appearance-none checked:appearance-auto checked:accent-white w-[18px] h-[18px] border-[3px] border-black/60 rounded-sm"/>
                                </div>
                            </div>
                            <div className="w-full border-b border-black/60">
                                <div className="grid grid-cols-3 justify-start items-center h-14 border-b border-zinc-300 w-full" style={{ gridTemplateColumns: '1.4fr 1fr 1fr' }}>
                                    <div></div>
                                    <div className="px-4 text-center text-black text-sm font-normal font-roboto leading-tight tracking-tight">View</div>
                                    <input type="checkbox"  className="appearance-none checked:appearance-auto checked:accent-white w-[18px] h-[18px] border-[3px] border-black/60 rounded-sm"/>
                                </div>
                                <div className="grid grid-cols-3 justify-start items-center h-14 border-b border-zinc-300 w-full" style={{ gridTemplateColumns: '1.4fr 1fr 1fr' }}>
                                    <div className="px-4 text-center text-black text-sm font-normal font-roboto leading-tight tracking-tight">All Departments</div>
                                    <div className="px-4 text-center text-black text-sm font-normal font-roboto leading-tight tracking-tight">Add/Edit</div>
                                    <input type="checkbox"  className="appearance-none checked:appearance-auto checked:accent-white w-[18px] h-[18px] border-[3px] border-black/60 rounded-sm"/>
                                </div>
                                <div className="grid grid-cols-3 justify-start items-center h-14 w-full" style={{ gridTemplateColumns: '1.4fr 1fr 1fr' }}>
                                    <div></div>
                                    <div className="px-4 text-center text-black text-sm font-normal font-roboto leading-tight tracking-tight">Delete</div>
                                    <input type="checkbox"  className="appearance-none checked:appearance-auto checked:accent-white w-[18px] h-[18px] border-[3px] border-black/60 rounded-sm"/>
                                </div>
                            </div>
                            <div className="w-full border-b border-black/60">
                                <div className="grid grid-cols-3 justify-start items-center h-14 border-b border-zinc-300 w-full" style={{ gridTemplateColumns: '1.4fr 1fr 1fr' }}>
                                    <div></div>
                                    <div className="px-4 text-center text-black text-sm font-normal font-roboto leading-tight tracking-tight">View</div>
                                    <input type="checkbox"  className="appearance-none checked:appearance-auto checked:accent-white w-[18px] h-[18px] border-[3px] border-black/60 rounded-sm"/>
                                </div>
                                <div className="grid grid-cols-3 justify-start items-center h-14 border-b border-zinc-300 w-full" style={{ gridTemplateColumns: '1.4fr 1fr 1fr' }}>
                                    <div className="px-4 text-center text-black text-sm font-normal font-roboto leading-tight tracking-tight">Attendances</div>
                                    <div className="px-4 text-center text-black text-sm font-normal font-roboto leading-tight tracking-tight">Add/Edit</div>
                                    <input type="checkbox"  className="appearance-none checked:appearance-auto checked:accent-white w-[18px] h-[18px] border-[3px] border-black/60 rounded-sm"/>
                                </div>
                                <div className="grid grid-cols-3 justify-start items-center h-14 w-full" style={{ gridTemplateColumns: '1.4fr 1fr 1fr' }}>
                                    <div></div>
                                    <div className="px-4 text-center text-black text-sm font-normal font-roboto leading-tight tracking-tight">Delete</div>
                                    <input type="checkbox"  className="appearance-none checked:appearance-auto checked:accent-white w-[18px] h-[18px] border-[3px] border-black/60 rounded-sm"/>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col flex-initial w-[390px]">
                            <div className="grid grid-cols-3 justify-start items-center h-14 bg-zinc-100 border-t border-b border-zinc-300 w-full" style={{ gridTemplateColumns: '1.4fr 1fr 1fr' }}>
                                <div className="px-4 text-center text-black text-sm font-medium font-roboto leading-tight tracking-tight">Page Name</div>
                                <div className="px-4 text-center text-black text-sm font-medium font-roboto leading-tight tracking-tight">Roles</div>
                                <input type="checkbox"  className="appearance-none w-[18px] h-[18px] border-[3px] border-black/60 rounded-sm"/>
                            </div>
                            <div className="w-full border-b border-black/60">
                                <div className="grid grid-cols-3 justify-start items-center h-14 border-b border-zinc-300 w-full" style={{ gridTemplateColumns: '1.4fr 1fr 1fr' }}>
                                    <div></div>
                                    <div className="px-4 text-center text-black text-sm font-normal font-roboto leading-tight tracking-tight">View</div>
                                    <input type="checkbox"  className="appearance-none checked:appearance-auto checked:accent-white w-[18px] h-[18px] border-[3px] border-black/60 rounded-sm"/>
                                </div>
                                <div className="grid grid-cols-3 justify-start items-center h-14 border-b border-zinc-300 w-full" style={{ gridTemplateColumns: '1.4fr 1fr 1fr' }}>
                                    <div className="px-4 text-center text-black text-sm font-normal font-roboto leading-tight tracking-tight">Payroll</div>
                                    <div className="px-4 text-center text-black text-sm font-normal font-roboto leading-tight tracking-tight">Add/Edit</div>
                                    <input type="checkbox"  className="appearance-none checked:appearance-auto checked:accent-white w-[18px] h-[18px] border-[3px] border-black/60 rounded-sm"/>
                                </div>
                                <div className="grid grid-cols-3 justify-start items-center h-14 w-full" style={{ gridTemplateColumns: '1.4fr 1fr 1fr' }}>
                                    <div></div>
                                    <div className="px-4 text-center text-black text-sm font-normal font-roboto leading-tight tracking-tight">Delete</div>
                                    <input type="checkbox"  className="appearance-none checked:appearance-auto checked:accent-white w-[18px] h-[18px] border-[3px] border-black/60 rounded-sm"/>
                                </div>
                            </div>
                            <div className="w-full border-b border-black/60">
                                <div className="grid grid-cols-3 justify-start items-center h-14 border-b border-zinc-300 w-full" style={{ gridTemplateColumns: '1.4fr 1fr 1fr' }}>
                                    <div></div>
                                    <div className="px-4 text-center text-black text-sm font-normal font-roboto leading-tight tracking-tight">View</div>
                                    <input type="checkbox"  className="appearance-none checked:appearance-auto checked:accent-white w-[18px] h-[18px] border-[3px] border-black/60 rounded-sm"/>
                                </div>
                                <div className="grid grid-cols-3 justify-start items-center h-14 border-b border-zinc-300 w-full" style={{ gridTemplateColumns: '1.4fr 1fr 1fr' }}>
                                    <div className="px-4 text-center text-black text-sm font-normal font-roboto leading-tight tracking-tight">Jobs</div>
                                    <div className="px-4 text-center text-black text-sm font-normal font-roboto leading-tight tracking-tight">Add/Edit</div>
                                    <input type="checkbox"  className="appearance-none checked:appearance-auto checked:accent-white w-[18px] h-[18px] border-[3px] border-black/60 rounded-sm"/>
                                </div>
                                <div className="grid grid-cols-3 justify-start items-center h-14 w-full" style={{ gridTemplateColumns: '1.4fr 1fr 1fr' }}>
                                    <div></div>
                                    <div className="px-4 text-center text-black text-sm font-normal font-roboto leading-tight tracking-tight">Delete</div>
                                    <input type="checkbox"  className="appearance-none checked:appearance-auto checked:accent-white w-[18px] h-[18px] border-[3px] border-black/60 rounded-sm"/>
                                </div>
                            </div>
                            <div className="w-full border-b border-black/60">
                                <div className="grid grid-cols-3 justify-start items-center h-14 border-b border-zinc-300 w-full" style={{ gridTemplateColumns: '1.4fr 1fr 1fr' }}>
                                    <div></div>
                                    <div className="px-4 text-center text-black text-sm font-normal font-roboto leading-tight tracking-tight">View</div>
                                    <input type="checkbox"  className="appearance-none checked:appearance-auto checked:accent-white w-[18px] h-[18px] border-[3px] border-black/60 rounded-sm"/>
                                </div>
                                <div className="grid grid-cols-3 justify-start items-center h-14 border-b border-zinc-300 w-full" style={{ gridTemplateColumns: '1.4fr 1fr 1fr' }}>
                                    <div className="px-4 text-center text-black text-sm font-normal font-roboto leading-tight tracking-tight">Candidates</div>
                                    <div className="px-4 text-center text-black text-sm font-normal font-roboto leading-tight tracking-tight">Add/Edit</div>
                                    <input type="checkbox"  className="appearance-none checked:appearance-auto checked:accent-white w-[18px] h-[18px] border-[3px] border-black/60 rounded-sm"/>
                                </div>
                                <div className="grid grid-cols-3 justify-start items-center h-14 w-full" style={{ gridTemplateColumns: '1.4fr 1fr 1fr' }}>
                                    <div></div>
                                    <div className="px-4 text-center text-black text-sm font-normal font-roboto leading-tight tracking-tight">Delete</div>
                                    <input type="checkbox"  className="appearance-none checked:appearance-auto checked:accent-white w-[18px] h-[18px] border-[3px] border-black/60 rounded-sm"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default EditPermission