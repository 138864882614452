import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { FilterModalHandle } from '../../Partials/FilterModal'
import { IoOptionsOutline } from "react-icons/io5"
import { EmployeeAttendanceData } from '../../models/Attendance'
import { AttendanceService } from '../../Services/Attendance.service'
import Searchbar from '../../Components/Ui/Searchbar'
import PaginationSection, { PageData } from '../../Components/PaginationSection'
import placeholderImage from '../../assets/placeholderImage.png'
import { MdOutlineLocalPrintshop } from "react-icons/md";
import { useReactToPrint } from "react-to-print";
import CustomPrint from './CustomPrint';

type AttendanceProps = {
    filterAttendanceRef : React.RefObject<FilterModalHandle>
    attendanceService : AttendanceService
}

export const Attendance = ({attendanceService,filterAttendanceRef}:AttendanceProps) => {
    // -------------------Handling Fetching Employees---------------------
    const [employees, setEmployees] = useState<EmployeeAttendanceData[]>([])
    const token = localStorage.getItem('token')

    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [pageData, setPageData] = useState<PageData>({ 
    page_size: 0,
    page_count: 0,
    total_items: 0,
    page_number: 1})

  // ---------------------------------fetching Attendance filterParams----------------------
  const location = useLocation()
  const filterParams = location.state?.filterParams

  const [searchInput, setSearchInput] = useState('')

  const formatDate = (dateString:string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
    }

    const componentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
      const fetchData = async () => {

          try{
              attendanceService.setToken(token)
              // if filterParams are not available fetch whole datas
              if (!searchInput){
                  const res = await attendanceService.getAttendances({
                      page: page,
                      pageSize: pageSize,
                      name: filterParams?.searchInput || '',
                      dateFrom: filterParams?.dateFrom || '',
                      dateTo: filterParams?.dateTo || '',
                  });
                  const sortedEmployees = res.attendance_data.sort((a: any, b: any) => {
                    const dateA = new Date(a.checkin_time);
                    const dateB = new Date(b.checkin_time);
                    return dateB.getTime() - dateA.getTime(); // Descending order
                })
                  setEmployees(sortedEmployees);
                  setPageData(res.meta);
              }
              else
              {
                const res = await attendanceService.getAttendances({
                    page: page,
                    pageSize: pageSize,
                    name: searchInput || '',
                    dateFrom: filterParams?.dateFrom || '',
                    dateTo:filterParams?.dateTo || ''
                })
                const sortedEmployees = res.attendance_data.sort((a: any, b: any) => {
                    const dateA = new Date(a.checkin_time);
                    const dateB = new Date(b.checkin_time);
                    return dateB.getTime() - dateA.getTime(); // Descending order
                })
                setEmployees(sortedEmployees);
                setPageData(res.meta)
              }
            
          } catch (exception) {
              console.log(exception)
          }
      }
      fetchData()
  },[page,pageSize,location.state,token,searchInput,filterParams,attendanceService])

  // ----------------------------------formatting time ---------------------
    const formatTime = (timeString:string) => {
        return new Date(timeString).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        const timeParts = timeString.split('T');
        // const date = timeParts[0];
        const time = timeParts[1].split(':');
        
        const hours = time[0];
        const minutes = time[1];

        return `${hours}:${minutes}`;
    }
  
    const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(event.target.value)
        setPageSize(10) // Reset pageSize to 10 on search input change
        setPage(1) // Reset page to 1 on search input change
    }

    const showFilterModal = () => {
      if (filterAttendanceRef.current) {
        filterAttendanceRef.current.show();
      }
    }

    const [isPrinting,setIsPrinting] = useState<boolean>(false)
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onBeforeGetContent: ()=> setIsPrinting(true),
        onAfterPrint: ()=> setIsPrinting(false),
    })
    
    const proceedWithPrinting = () => {
        setIsPrinting(true);
        setTimeout(()=>handlePrint(),1)
    };

  return (
      <>
          <div className='w-full ml-4 rounded-[10px] border border-zinc-400/20 mx-4 max-sm:w-[94%]'>
              {/* --------------------filter section ------------------------- */}
                <div className='flex justify-between w-full px-4 pr-8 py-4 flex-wrap gap-2'>
                    <div className='w-[400px]'>
                        <Searchbar changeEvent={handleSearchInput}/>
                    </div>
                    <div className='flex gap-5 '>
                        <button className="w-[111px] h-[50px] p-5 rounded-[10px] border border-zinc-400/20 justify-center items-center gap-2.5 inline-flex"
                        onClick={proceedWithPrinting}>
                                <div className='flex items-center'>
                                    <MdOutlineLocalPrintshop  className='h-6 w-6'/>
                                </div>
                                <div className="text-zinc-900 text-[20px] font-light font-kantipur leading-normal"

                                >{'lk|G6'}</div>
                            </button>
                        <button onClick={showFilterModal}
                        className="w-[111px] h-[50px] p-5 rounded-[10px] border border-zinc-400/20 justify-center items-center gap-2.5 inline-flex">
                            <div className='flex items-center'>
                                <IoOptionsOutline  className='h-6 w-6'/>
                            </div>
                            <div className="text-zinc-900 text-[20px] font-light font-kantipur leading-normal"
                            >{'lkmn\\6/'}</div>
                        </button>
                        
                    </div>
                    
                    
                </div>
              <div className='max-xl:overflow-x-scroll'>
              {/* -------------------------------------table------------------------------- */}
              
              <div className='min-w-full w-full' ref={componentRef}>
                {isPrinting && <CustomPrint employees={employees} filterParams={filterParams}/>}
                  {/* ------------------------------------table Heading------------------------------ */}
                  <div className='grid grid-cols-8 gap-2 px-4 py-4 border-b border-zinc-400/10' style={{ gridTemplateColumns: '1.5fr 0.6fr 0.6fr 0.8fr 0.8fr 0.8fr 0.8fr 0.6fr' }}>
                      <div className="text-zinc-400 text-[22px] font-light font-kantipur leading-normal min-w-[150px]">{'sd{rf/Lsf] gfd'}</div>
                      <div className="text-zinc-400 text-[22px] font-light font-kantipur leading-normal min-w-[100px]">{'ljefu'}</div>
                      <div className="text-zinc-400 text-[22px] font-light font-kantipur leading-normal min-w-[100px]">{'k|sf/'}</div>
                      <div className="text-zinc-400 text-[22px] font-light font-kantipur leading-normal min-w-[100px]">{'ldlt'}</div>
                      <div className="text-zinc-400 text-[22px] font-light font-kantipur leading-normal min-w-[100px]">{'r]s Og 6fOd'}</div>
                      <div className="text-zinc-400 text-[22px] font-light font-kantipur leading-normal min-w-[100px]">{'r]s cfpt  6fOd'}</div>
                      <div className="text-zinc-400 text-[22px] font-light font-kantipur leading-normal min-w-[100px]">{'l:ylt'}</div>
                      <div className="text-zinc-400 text-[22px] font-light font-kantipur leading-normal min-w-[100px]">{'sfd ug]{ ;do '}</div>
                  </div>
                  {/* ---------------------table content--------------------------------- */}
                  <div className=''>
                      {employees.map((employee,index) => (
                      <div key={index} className='grid grid-cols-8 gap-2 px-4 border-b border-zinc-400/10 items-center' style={{ gridTemplateColumns: '1.5fr 0.6fr 0.6fr 0.8fr 0.8fr 0.8fr 0.8fr 0.6fr' }}>
                          <div className='h-11 pr-2.5 py-1 justify-start items-center gap-2.5 inline-flex min-w-[150px]'>
                              <img className="w-9 h-9 rounded-full" src={employee.employee.image ? employee.employee.image : placeholderImage} alt='employeeImage' />
                              <div className="text-zinc-900 text-base font-light font-lexend leading-normal">{employee.employee.name}</div>
                          </div>

                          <div className="text-zinc-900 text-base font-light font-lexend leading-normal min-w-[100px]">{employee.employee.position}</div>
                          <div className="text-zinc-900 text-base font-light font-lexend leading-normal min-w-[100px]">{employee.employee.privilege}</div>
                          <div className="text-zinc-900 text-base font-light font-lexend leading-normal min-w-[100px]">{formatDate(employee.checkin_time)}</div>
                          <div className="text-zinc-900 text-base font-light font-lexend leading-normal min-w-[100px]">{formatTime(employee.checkin_time)}</div>
                          <div className="text-zinc-900 text-base font-light font-lexend leading-normal min-w-[100px]">{formatTime(employee.checkout_time)}</div>
                          <div className="text-zinc-900 text-base font-light font-lexend leading-normal min-w-[100px]">
                              <div className="w-[78px] h-6 px-2 py-[2px] bg-indigo-500/10 rounded justify-center items-center gap-2.5 inline-flex">
                                  <div className="text-indigo-500 text-xs font-light font-['Lexend'] leading-[18px]">{employee.status}</div>
                              </div>
                          </div>                         
                          <div className="text-zinc-900 text-base font-light font-lexend leading-normal min-w-[100px]">{employee.work_hours}</div>
                      </div>
                      ))}

                  </div>
              </div>
                {/* -----------------------------------Pagination Section--------------------------------- */}
                <PaginationSection
                page = {page}
                setPage = {setPage}
                pageSize={pageSize}
                setPageSize = {setPageSize}
                pageData = {pageData}/>
            </div>
          </div>
      </>
  )
}