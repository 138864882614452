import React from 'react'

export const Otppage = () => {
  return (
    <>
          
        <div className="flex h-[100vh] justify-center items-center">
                
            <div className="flex-col justify-start items-start gap-[30px] inline-flex">
                <div className="flex-col justify-start items-start gap-[5px] flex">
                    <div className="w-[445px] text-zinc-900 text-3xl font-semibold font-['Lexend'] leading-10">Enter OTP</div>
                    <div className="w-[445px] text-zinc-400 text-base font-light font-['Lexend'] leading-normal">The code has been sent to the registered email address, enter the OTP to verify the details.</div>
                </div>
                <div className="w-[445px] flex-col justify-start items-start gap-8 flex">
                    <div className="w-[380px] justify-start items-start gap-9 inline-flex">
                        <input onChange={(e)=>{e.preventDefault();console.log('e.target.value')}} type="number" id="otpControl1" className="w-[60px] h-[60px] text-center text-zinc-900 text-2xl font-semibold font-['Lexend'] leading-9 bg-white rounded-[10px] border border-red-700" />
                        <input onChange={(e)=>{e.preventDefault();console.log('e.target.value')}} type="number" id="otpControl2" className="w-[60px] h-[60px] text-center text-zinc-900 text-2xl font-semibold font-['Lexend'] leading-9 bg-white rounded-[10px] border border-red-700" />
                        <input onChange={(e)=>{e.preventDefault();console.log('e.target.value')}} type="number" id="otpControl3" className="w-[60px] h-[60px] text-center text-zinc-900 text-2xl font-semibold font-['Lexend'] leading-9 bg-white rounded-[10px] border border-zinc-400/opacity-20" />
                        <input onChange={(e)=>{e.preventDefault();console.log('e.target.value')}} type="number" id="otpControl4" className="w-[60px] h-[60px] text-center text-zinc-900 text-2xl font-semibold font-['Lexend'] leading-9 bg-white rounded-[10px] border border-zinc-400/opacity-20" />
                        <input onChange={(e)=>{e.preventDefault();console.log('e.target.value')}} type="number" id="otpControl5" className="w-[60px] h-[60px] text-center text-zinc-900 text-2xl font-semibold font-['Lexend'] leading-9 bg-white rounded-[10px] border border-zinc-400/opacity-20" />
                    </div>
                    <div className="w-[445px] h-14 p-5 bg-red-700 rounded-[10px] justify-center items-center gap-2.5 inline-flex">
                    <button type="submit" onClick={()=>{console.log('clicked')}} className="text-white text-base font-medium font-['Lexend'] leading-normal w-full">Verify</button>
                    </div>
                    <div className="self-stretch grow shrink basis-0 px-[156px] justify-start items-center gap-[5px] inline-flex">
                    <button type="button" onClick={()=>console.log('sending OTP')} className="text-zinc-900 text-base font-light font-['Lexend'] leading-normal">Resend code (30)</button>
                    </div>
                </div>
            </div>

        </div>
        
    </>
  )
}