import React, { useState } from 'react'
import { IoPersonOutline} from "react-icons/io5";
import { BsFillPersonFill} from "react-icons/bs";
import document from '../../../assets/document.svg'
import accountlock from '../../../assets/accountlock.svg'
import { useNavigate, useParams } from 'react-router-dom';
import { EmployeeData } from '../../../models/Employee';
import ProfessionalInfoView from './ProfessionalInfoView';

const EmployeeDetailView:React.FC<{ employee: EmployeeData | undefined }> = ({employee}) => {
    const [selectedTab, setSelectedTab] = useState<string>('professional')
    const navigate = useNavigate()
    const {employeeId} = useParams()

    return(
        <>
            <div className='w-full mx-4 rounded-[10px] border border-zinc-400/20'>
                <div className='px-5 py-5'>
                    {/* --------------------Heading------------------------- */}
                    <div className="inline-flex items-center gap-5">
                        <div
                        className={`inline-flex items-center gap-2.5 pb-2 cursor-pointer ${
                            selectedTab === 'professional' ? 'border-b-4 border-secondRed' : ''
                        }`}
                        onClick={() => {setSelectedTab('professional')
                            navigate(`/allemployees/${employeeId}`)}
                        }>
                            {selectedTab === 'professional' ? (
                                <BsFillPersonFill className='w-5 h-5 rounded-full text-secondRed' />
                            ) : (
                                <IoPersonOutline className='w-5 h-5' />
                            )}
                            <p className={`text-[20px] font-light font-kantipur leading-6 ${selectedTab === 'professional' ? 'text-red-700 text-[20px] font-semibold font-kantipur leading-normal':''} max-sm:hidden`}>{'Jofj;flos hfgsf/L'}</p>
                        </div>

                        <div
                        className={`inline-flex items-center gap-2.5 pb-2 cursor-pointer ${
                            selectedTab === 'documents' ? 'border-b-4 border-secondRed' : ''
                        }`}
                        onClick={() => setSelectedTab('documents')}
                        >
                            <img src={document} alt='documentIcon'/>
                            <p className="text-[20px] font-light font-kantipur leading-6 max-sm:hidden">{'sfuhftx¿'}</p>
                        </div>

                        <div
                        className={`inline-flex items-center gap-2.5 pb-2 cursor-pointer ${
                            selectedTab === 'accountAccess' ? 'border-b-4 border-secondRed' : ''
                        }`}
                        onClick={() => setSelectedTab('accountAccess')}
                        >
                            <img src={accountlock} alt='lockIcon'/>
                            <p className="text-[20px] font-light font-kantipur leading-6 max-sm:hidden">{"vftf kx'r"}</p>
                        </div>

                    </div>
                    {/* --------------------Content------------------------- */}
                    {selectedTab === 'professional' && <ProfessionalInfoView employee={employee}/>}
                    

                </div>
            </div>
        </>
    )
}


export default EmployeeDetailView
