import React from 'react'
import { FaTimes } from 'react-icons/fa'
import { FaCheck } from 'react-icons/fa6'
import { EmployeeData } from '../../../models/Employee'
import { useParams } from 'react-router-dom'

interface ProfessionalInfoViewProps {
    employee : EmployeeData | undefined
    
}
const ProfessionalInfoView = ({employee}:ProfessionalInfoViewProps) => {
    const {employeeId} = useParams()
    return (
        <>
            <div>
                <div className='flex flex-col w-full pt-7 gap-5'>
                    <div className='flex flex-wrap w-full justify-between border-b gap-5 pb-2.5 border-zinc-400/10'>
                        <div className='flex-1 flex-col min-w-[300px] justify-start items-start space-y-2'>
                            <div className="text-zinc-400 text-sm font-light font-lexend leading-snug">ID</div>
                            <div className="text-zinc-900 text-base font-light font-lexend leading-normal">{employeeId}</div>
                        </div>
                        <div className='flex-1 flex-col min-w-[300px] justify-start items-start space-y-2'>
                            <div className="text-zinc-400 text-sm font-light font-lexend leading-snug">Full Name</div>
                            <div className="text-zinc-900 text-base font-light font-lexend leading-normal">{employee?.name}</div>
                        </div>
                    </div>
                    <div className='flex flex-wrap w-full justify-between border-b gap-5 pb-2.5 border-zinc-400/10'>
                        <div className='flex-1 flex-col min-w-[300px] justify-start items-start space-y-2'>
                            <div className="text-zinc-400 text-sm font-light font-lexend leading-snug">Position</div>
                            <div className="text-zinc-900 text-base font-light font-lexend leading-normal">{employee?.position}</div>
                        </div>
                        <div className='flex-1 flex-col min-w-[300px] justify-start items-start space-y-2'>
                            <div className="text-zinc-400 text-sm font-light font-lexend leading-snug">Department</div>
                            <div className="text-zinc-900 text-base font-light font-lexend leading-normal">{employee?.department}</div>
                        </div>
                    </div>  
                    <div className='flex flex-wrap w-full justify-between border-b gap-5 pb-2.5 border-zinc-400/10'>
                        <div className='flex-1 flex-col min-w-[300px] justify-start items-start space-y-2'>
                            <div className="text-zinc-400 text-sm font-light font-lexend leading-snug">Email</div>
                            <div className="text-zinc-900 text-base font-light font-lexend leading-normal">{employee?.email}</div>
                        </div>
                        <div className='flex-1 flex-col min-w-[300px] justify-start items-start space-y-2'>
                            <div className="text-zinc-400 text-sm font-light font-lexend leading-snug">Phone</div>
                            <div className="text-zinc-900 text-base font-light font-lexend leading-normal">{employee?.phone}</div>
                        </div>
                    </div>                   
                    <div className='flex flex-wrap w-full justify-between border-b gap-5 pb-2.5 border-zinc-400/10'>
                        <div className='flex-1 flex-col min-w-[300px] justify-start items-start space-y-2'>
                            <div className="text-zinc-400 text-sm font-light font-lexend leading-snug">Privilege</div>
                            <div className="text-zinc-900 text-base font-light font-lexend leading-normal">{employee?.privilege}</div>
                        </div>
                        <div className='flex-1 flex-col min-w-[300px] justify-start items-start space-y-2'>
                            <div className="text-zinc-400 text-sm font-light font-lexend leading-snug">Card Number</div>
                            <div className="text-zinc-900 text-base font-light font-lexend leading-normal">{employee?.card_number}</div>
                        </div>
                    </div>
                    <div className='flex flex-wrap w-full justify-between border-b gap-5 pb-2.5 border-zinc-400/10'>
                        <div className='flex-1 flex-col min-w-[300px] justify-start items-start space-y-2'>
                            <div className="text-zinc-400 text-sm font-light font-lexend leading-snug">Is enabled:</div>
                            <div className="text-zinc-900 text-base font-light font-lexend leading-normal">
                                {employee?.is_enabled ? <FaCheck /> : <FaTimes />}
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </>
    )
}

export default ProfessionalInfoView
