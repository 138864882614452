import Dropdown from "./Components/Ui/Dropdown"

const Design = () => {
  return (
    <>
      <div className="border rounded border-zinc-300 pb-6">
          <div className="flex justify-between pt-6 px-12">
              <div className="flex text-base font-medium font-lexend leading-snug">
                  Employee Status
              </div>
              <Dropdown>See all</Dropdown>
          </div>
          {/* ------------------------------------table Heading------------------------------ */}
          <div className='grid grid-cols-4 gap-2 px-4 py-4 border mt-3 border-zinc-300/50 place-items-center' style={{ gridTemplateColumns: '1.8fr 1fr 1.2fr 1fr' }}>
              <div className="text-zinc-500 text-xs font-semibold font-lexend leading-normal">Name</div>
              <div className="text-zinc-500 text-xs font-semibold font-lexend leading-normal">Email</div>
              <div className="text-zinc-500 text-xs font-semibold font-lexend leading-normal">Role</div>
              <div className="text-zinc-500 text-xs font-semibold font-lexend leading-normal">Status</div>
          </div>
          {/* ---------------------table content--------------------------------- */}
          <div className='grid grid-cols-4 gap-2 px-4 py-4 place-items-center' style={{ gridTemplateColumns: '1.8fr 1fr 1.2fr 1fr' }}>
              <div className="text-black text-xs font-semibold font-lexend leading-normal">Esther Howard</div>
              <div className="text-black text-xs font-normal font-lexend leading-normal">estherhoward@gmail.com</div>
              <div className="text-blue-800 text-xs font-medium font-lexend leading-normal">Junior Designer</div>
              <div className="w-[77px] h-[41.78px] px-4 py-1 bg-green-200 rounded-xl border border-lime-600 justify-start items-center gap-10 inline-flex">
                  <div className="text-lime-600 text-xs font-semibold font-lexend leading-normal">Present</div>
              </div>
          </div>
          <div className='grid grid-cols-4 gap-2 px-4 py-4 place-items-center' style={{ gridTemplateColumns: '1.8fr 1fr 1.2fr 1fr' }}>
              <div className="text-black text-xs font-semibold font-lexend leading-normal">Phil Collins</div>
              <div className="text-black text-xs font-normal font-lexend leading-normal">philcollins002@gmail.com</div>
              <div className="text-blue-800 text-xs font-medium font-lexend leading-normal">Sales Manager</div>
              <div className="w-[77px] h-[41.78px] px-4 py-1 bg-amber-100/70 rounded-xl border border-orange-400/70 justify-start items-center gap-10 inline-flex">
              <div className="text-orange-400/70 text-[10px] font-semibold font-lexend leading-normal">Withdraw</div>
              </div>
          </div>
          <div className='grid grid-cols-4 gap-2 px-4 py-4 place-items-center' style={{ gridTemplateColumns: '1.8fr 1fr 1.2fr 1fr' }}>
              <div className="text-black text-xs font-semibold font-lexend leading-normal">Jake Nicholson</div>
              <div className="text-black text-xs font-normal font-lexend leading-normal">jakeamerica052@gmail.com</div>
              <div className="text-blue-800 text-xs font-medium font-lexend leading-normal">Senior Developer</div>
              <div className="w-[77px] h-[41.78px] px-4 py-1 bg-green-200 rounded-xl border border-lime-600 justify-start items-center gap-10 inline-flex">
                  <div className="text-lime-600 text-xs font-semibold font-lexend leading-normal">Present</div>
              </div>
          </div>
          <div className='grid grid-cols-4 gap-2 px-4 py-4 place-items-center' style={{ gridTemplateColumns: '1.8fr 1fr 1.2fr 1fr' }}>
              <div className="text-black text-xs font-semibold font-lexend leading-normal">Esther Howard</div>
              <div className="text-black text-xs font-normal font-lexend leading-normal">estherhoward@gmail.com</div>
              <div className="text-blue-800 text-xs font-medium font-lexend leading-normal">Junior Designer</div>
              <div className="w-[77px] h-[41.78px] px-4 py-1 bg-red-300 rounded-xl border border-red-700 justify-start items-center gap-10 inline-flex">
                  <div className="text-red-700 text-xs font-semibold font-lexend leading-normal">Absent</div>
              </div>
          </div>
      </div>
    </>
  )
}

export default Design
