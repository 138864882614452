import { ReactNode } from 'react';

type DashboardElementProps = {
    isActive: boolean
    component: React.ComponentType<{ className: string }>
    children: ReactNode
    isCollapsed: boolean
}

const DashboardElement = ({ isActive, component: Component, children, isCollapsed }: DashboardElementProps) => {
    return (
        <div className={`group w-full flex items-center hover:bg-[#cac8c8] rounded-lg transition-all duration-300 ${isActive ? 'bg-[#cac8c8]' : ''}`}>
            <div className="w-[2px] h-full py-4 group-hover:bg-primary transition-all duration-100"></div>
            <Component className='mx-2 w-[20px] h-[20px]' />
            {!isCollapsed && <div className="ml-4 w-[120px] font-kantipur font-normal text-[25px] leading-6">{children}</div>}
        </div>
    )
}

export default DashboardElement;


// Usage
// import { BsGrid } from 'react-icons/bs';
// <DashboardElement isActive={false} component={BsGrid}>Dashboard</DashboardElement>